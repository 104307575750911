import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback } from "react";
import { useEventBoxContext } from "@/contexts/EventBoxContext";

const EventBoxDateTimePicker = ({ form, setForm }) => {
  const { errors, setErrors } = useEventBoxContext();

  const onChangeDateHandler = useCallback(
    (date) => {
      const now = new Date();
      if (date < now) {
        setErrors((prev) => ({
          ...prev,
          dueDatetime: "Date and time can't be in the past",
        }));
        return;
      }
      setForm((prev) => ({ ...prev, dueDatetime: date }));
      setErrors((prev) => ({ ...prev, dueDatetime: null }));
    },
    [setForm, setErrors],
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Select date and time"
        onChange={onChangeDateHandler}
        fullWidth
        slotProps={{
          textField: {
            size: "small",
            fullWidth: true,
            helperText: errors?.dueDatetime,
            error: !!errors?.dueDatetime,
          },
        }}
        sx={{ mb: 2 }}
        disablePast={true}
        name="dueDatetime"
        ampm={false}
        error={!!errors?.dueDatetime}
        value={form?.dueDatetime || null}
        format={"DD.MM.YYYY HH:mm"}
      />
    </LocalizationProvider>
  );
};

export default EventBoxDateTimePicker;
