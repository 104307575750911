import Modal from "@/components/ui/Modal";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useCreateClientMutation } from "@/redux/api/clientApiSlice";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { appendClient } from "@/redux/reducers/clientsSlice";
import { useSetSnackBar } from "@/hooks";
import ClientCreateAssignTo from "./ClientCreateAssignTo";

const ClientCreateForm = ({ open, setOpen }) => {
  const [form, setForm] = useState({});
  const snackBar = useSetSnackBar();
  const dispatch = useDispatch();
  const [createClient] = useCreateClientMutation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const phoneHelperText = errors.phone
    ? errors.phone[0]
    : "Phone number must be a national number : +1 555 555 5555";
  const handleCreate = async (e, variant) => {
    e.preventDefault();
    try {
      const response = await createClient(form).unwrap();
      if (variant === "createAndContinueEditing") navigate(`/${response.id}`);
      dispatch(appendClient(response));
      snackBar("Client created successfully!", "success", true);
      if (variant === "create") {
        closeHandler();
        return;
      }
      setForm({});
      setErrors({});
    } catch (error) {
      setErrors(error.data);
    }
  };
  const inputChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value }); // [5]
  };
  const closeHandler = () => {
    setOpen(false);
    setForm({});
    setErrors({});
  };
  return (
    <Modal
      open={open}
      closeHandler={closeHandler}
      title="Create Client"
      maxWidth={"xs"}
    >
      <form
        className="grid gap-y-4 py-2"
        onSubmit={(e) => handleCreate(e, "create")}
      >
        <ClientCreateAssignTo
          value={form?.assignedTo || ""}
          onChange={inputChangeHandler}
        />
        <TextField
          fullWidth
          error={!!errors?.firstName}
          label="First nane"
          variant="outlined"
          name={"firstName"}
          value={form?.firstName || ""}
          onChange={inputChangeHandler}
          required
          helperText={errors.firstName && errors.firstName[0]}
        />
        <TextField
          fullWidth
          error={!!errors?.lastName}
          label="Last nane"
          variant="outlined"
          value={form?.lastName || ""}
          name={"lastName"}
          onChange={inputChangeHandler}
          helperText={errors?.lastName && errors?.lastName[0]}
          required
        />
        <TextField
          fullWidth
          error={!!errors?.email}
          label="Email"
          variant="outlined"
          value={form?.email || ""}
          name={"email"}
          onChange={inputChangeHandler}
          required
          helperText={errors?.email && errors?.email[0]}
        />
        <TextField
          fullWidth
          error={!!errors?.phone}
          label="Phone"
          variant="outlined"
          value={form?.phone || ""}
          name={"phone"}
          onChange={inputChangeHandler}
          required
          helperText={phoneHelperText}
        />

        <div className={"flex flex-col space-y-2"}>
          <Button
            variant="contained"
            size="large"
            onClick={(e) => handleCreate(e, "create")}
          >
            Create
          </Button>
          <Button
            size="small"
            variant="text"
            onClick={(e) => handleCreate(e, "createAndAddAnother")}
          >
            Create and add another
          </Button>
          <Button
            size="small"
            variant="text"
            onClick={(e) => handleCreate(e, "createAndContinueEditing")}
          >
            Create and continue editing
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ClientCreateForm;
