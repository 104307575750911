import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const validateDate = (date) => {
  return dayjs(date).isValid();
};
const DateFilter = ({ dates, handleFiltersChange, removeSearchParams }) => {
  const { actionTimeBefore, actionTimeAfter } = dates;
  const onDateChange = (key, value) => {
    if (!validateDate(value)) {
      removeSearchParams(key);
      return;
    }
    handleFiltersChange(key, value?.format("YYYY-MM-DD"));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        size="small"
        value={actionTimeAfter ? dayjs(actionTimeAfter) : null}
        slotProps={{ textField: { size: "small" } }}
        label={"Select date from"}
        onChange={(value) => {
          onDateChange("actionTimeAfter", value);
        }}
        format="DD-MM-YYYY"
      />
      <DatePicker
        size="small"
        value={actionTimeBefore ? dayjs(actionTimeBefore) : null}
        slotProps={{ textField: { size: "small" } }}
        label={"Select date to"}
        onChange={(value) => {
          onDateChange("actionTimeBefore", value);
        }}
        format="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};

export default React.memo(DateFilter);
