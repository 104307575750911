import { Grid, TextField } from "@mui/material";
import { useGetAssignedToListQuery } from "@/redux/api/usersApiSlice";
import { useGetClientsStatusesQuery } from "@/redux/api/clientApiSlice";
import { canUpdateClientField } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

const SingleClientInfoSelectGroup = ({
  onChangeHandler,
  form,
  client,
  errors,
}) => {
  const { data: assignedToList } = useGetAssignedToListQuery();
  const { data: clientStatuses } = useGetClientsStatusesQuery();
  const permissions = useSelector(selectPermissions);

  const selectedClientData = {
    assignedTo: { label: "Assigned to", data: assignedToList || [] },
    status: { label: "Status", data: clientStatuses || [] },
  };

  return (
    <Grid container spacing={1}>
      {Object.entries(selectedClientData).map(([key, value]) => {
        if (key === "assignedTo" && !canUpdateClientField(key, permissions)) {
          return null;
        }
        return (
          <Grid key={key} item xs={6}>
            <TextField
              name={key}
              label={value.label}
              variant="outlined"
              margin="dense"
              size="small"
              select
              error={!!errors[key]}
              helperText={errors[key] && errors[key][0]}
              disabled={!canUpdateClientField(key, permissions)}
              onChange={onChangeHandler}
              value={form?.[key] || client?.[key] || ""}
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              <option value="" />
              {value?.data?.map(({ id, ...rest }) => (
                <option key={id} value={id}>
                  {rest?.name || rest?.username || id}
                </option>
              ))}
            </TextField>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SingleClientInfoSelectGroup;
