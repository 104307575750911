import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useUpdateUserMutation } from "@/redux/api/usersApiSlice";
import { useState, memo } from "react";
import { parseError } from "@/utils";
import { useSetSnackBar } from "@/hooks";
const SetNewPassword = ({ userId }) => {
  const [updateUser] = useUpdateUserMutation();
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState({});
  const snackbar = useSetSnackBar();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser({
        id: userId,
        password: newPassword,
      }).unwrap();
      setNewPassword("");
      snackbar("Password changed", "success");
    } catch (error) {
      if (error?.data?.detail) {
        return snackbar(error.data.detail, "error");
      }
      for (const field in error.data) {
        setErrors((prev) => ({ ...prev, [field]: error.data[field] }));
      }
    }
  };
  const onChangeHandler = (e) => {
    setNewPassword(e.target.value);
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }
  };
  return (
    <Box as={"form"} sx={{ mt: 1 }} onSubmit={handleSubmit}>
      <TextField
        type="password"
        name={"new-password"}
        autoComplete={"new-password"}
        fullWidth
        label="New password"
        onChange={onChangeHandler}
        value={newPassword}
        error={!!errors?.password}
        helperText={errors?.password ? parseError(errors?.password) : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" aria-label="Change password">
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default memo(SetNewPassword);
