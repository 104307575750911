import ConfirmAlert from "@/components/ui/ConfirmAlert";
import { useEventBoxContext } from "@/contexts/EventBoxContext";
import { TextField, Typography } from "@mui/material";
import { useState } from "react";
import { onEnterPress } from "@/utils";

const EventBoxConfirm = () => {
  const { confirmEvent, confirmClose, setIsFinished } = useEventBoxContext();
  const open = !!confirmEvent?.id;
  const [description, setDescription] = useState(
    confirmEvent?.description || "",
  );
  const confirmHandler = () => {
    setIsFinished(confirmEvent.id, description);
    confirmClose();
    setDescription("");
  };
  return (
    <ConfirmAlert
      title={"Finish event"}
      open={open}
      onAgreeCB={confirmHandler}
      onCloseCB={confirmClose}
      sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {`Are you sure you want to finish the event: ${
          confirmEvent?.typeName
        } - ${new Date(confirmEvent?.dueDatetime).toLocaleString()}?`}
      </Typography>
      <TextField
        label="Add comment"
        multiline
        rows={2}
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        fullWidth
        onKeyDown={(e) => onEnterPress(e, confirmHandler)}
        helperText={"Can shift+enter to add comment"}
      />
    </ConfirmAlert>
  );
};

export default EventBoxConfirm;
