/**
 * Цей компонент відповідає за відображення коментарів та можливість додавання нового коментаря
 * @returns {JSX.Element} - повертає JSX елемент
 */

// Підключаємо необхідні бібліотеки
import { Typography } from "@mui/material";

import CommentBoxList from "@/components/comon/CommentBox/CommentBoxList";
import CommentBoxContextProvider from "@/contexts/CommentBoxContext";
import CommentBoxAdd from "@/components/comon/CommentBox/CommentBoxAdd";

// Основна функція
const CommentBox = ({ clientId, ...rest }) => {
  return (
    <CommentBoxContextProvider clientId={clientId}>
      <Typography variant="h6" component={"h2"} sx={{ mb: 1 }}>
        Comments
      </Typography>
      <CommentBoxAdd />
      <CommentBoxList />
    </CommentBoxContextProvider>
  );
};

export default CommentBox;
