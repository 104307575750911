import React from "react";
import { useClientContext } from "@/contexts/ClientContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const ClientCreateAssignTo = ({ value, onChange }) => {
  const { assignedTo } = useClientContext();
  if (assignedTo.length === 0) {
    return null;
  }
  return (
    <FormControl fullWidth>
      <InputLabel>Assigned To</InputLabel>
      <Select
        value={value}
        label="Assigned To"
        name="assignedTo"
        onChange={onChange}
      >
        {assignedTo.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.username}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(ClientCreateAssignTo);
