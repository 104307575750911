import { Box, Drawer, IconButton } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { mainNav } from "@/routes";
import MainNavItem from "@/components/layouts/MainLayout/MainNav/MainNavItem";
import { useState } from "react";
import { canViewByModel } from "@/utils/permission";

const MainNavMob = ({ permissions }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: { sm: "", md: "none" } }}>
      <IconButton
        color="inherit"
        size="small"
        aria-label="menu"
        onClick={() => setOpen(true)}
      >
        <MenuOutlinedIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box sx={{ minWidth: "60dvw" }}>
          {mainNav.map((item, index) =>
            canViewByModel(item.app, item.model, permissions) ? (
              <MainNavItem {...item} key={index} />
            ) : null,
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default MainNavMob;
