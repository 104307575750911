import { Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useCanDeleteByModel } from "@/utils/permission";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedClientsIds,
  setSelectedClientsIds,
} from "@/redux/reducers/clientsSlice";
import { useState } from "react";
import DeleteClientsConfirm from "./DeleteClientsConfirm";
import { useMassDeleteClientsMutation } from "@/redux/api/clientApiSlice";
import { useSetSnackBar } from "@/hooks";
import { useClientContext } from "@/contexts/ClientContext";
const DeleteClients = () => {
  const canDelete = useCanDeleteByModel("client", "client");
  const snackBar = useSetSnackBar();
  const selectedClient = useSelector(selectSelectedClientsIds);
  const dispatch = useDispatch();
  const { clientsApiRef } = useClientContext();

  const [massDelete] = useMassDeleteClientsMutation();
  const [open, setOpen] = useState(false);
  if (!canDelete) return null;
  if (selectedClient.length === 0) return null;
  const handleDelete = async () => {
    try {
      const response = await massDelete({ clients: selectedClient }).unwrap();
      snackBar(response.detail, "success", true);
      dispatch(setSelectedClientsIds([]));
      clientsApiRef.current.refetch();
      setOpen(false);
    } catch (error) {
      snackBar(error.data.detail, "error", true);
    }
  };
  return (
    <>
      <Button
        sx={{ px: 2 }}
        color="error"
        onClick={() => setOpen(true)}
        endIcon={<DeleteOutlineOutlinedIcon />}
      >
        Delete
      </Button>
      <DeleteClientsConfirm
        open={open}
        setOpen={setOpen}
        count={selectedClient.length}
        deleteHandler={handleDelete}
      />
    </>
  );
};

export default DeleteClients;
