import { useContext } from "react";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import ColorModeContext from "@/contexts/ColorModeContext";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

const ThemeSwitcher = ({ as: Element = "button" }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <Tooltip title="Switch theme">
      <IconButton onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === "dark" ? (
          <LightModeOutlinedIcon fontSize="small" />
        ) : (
          <DarkModeOutlinedIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ThemeSwitcher;
