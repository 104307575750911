/*
Цей файл створює контекст для подій, які відбуваються в додатку.
 */

import { createContext, useContext, useState } from "react";
import {
  useCreatePlanningMutation,
  useUpdatePlanningMutation,
} from "@/redux/api/planningApiSlice";
import { useSetSnackBar } from "@/hooks";

const initialState = {
  addedEvents: [],
  addEvent: () => {},
  errors: {},
  setErrors: () => {},
  clientId: null,
  confirmEvent: {},
  confirmClose: () => {},
  confirmShow: () => {},
  setIsFinished: () => {},
};

const EventBoxContext = createContext(initialState);

export const useEventBoxContext = () => {
  return useContext(EventBoxContext);
};

const EventBoxContextProvider = ({ children, clientId }) => {
  const [events, setEvents] = useState(initialState.addedEvents);
  const [errors, setErrors] = useState(initialState.errors);
  const [confirmEvent, setConfirmEvent] = useState(initialState.confirmEvent);
  const [createPlanning] = useCreatePlanningMutation();
  const [updatePlanning] = useUpdatePlanningMutation();
  const snackbar = useSetSnackBar();
  const addEvent = async (event) => {
    try {
      const response = await createPlanning(event).unwrap();
      setEvents((prev) => [...prev, response]);
      snackbar("Event added", "success");
    } catch (error) {
      if (error.data?.detail) {
        snackbar(error.data.detail, "error");
      } else {
        setErrors(error.data || {});
      }
    }
  };

  const setIsFinished = async (eventId, description) => {
    try {
      const response = await updatePlanning({
        id: eventId,
        isFinished: true,
        description: description,
      }).unwrap();
      setEvents((prev) => {
        const index = prev.findIndex((event) => event.id === eventId);
        if (index !== -1) {
          prev[index] = response;
          return [...prev];
        }
        return [...prev, response];
      });
      snackbar("Event finished", "success");
    } catch (error) {
      if (error.data?.detail) {
        snackbar(error.data.detail, "error");
      } else {
        for (const key in error.data) {
          snackbar(error.data[key], "error");
        }
      }
    }
  };
  const confirmClose = () => {
    setConfirmEvent({});
  };
  const confirmShow = (event) => {
    setConfirmEvent(event);
  };

  return (
    <EventBoxContext.Provider
      value={{
        addedEvents: events,
        addEvent: addEvent,
        errors: errors,
        setErrors: setErrors,
        clientId: clientId,
        confirmClose: confirmClose,
        confirmEvent: confirmEvent,
        confirmShow: confirmShow,
        setIsFinished: setIsFinished,
      }}
    >
      {children}
    </EventBoxContext.Provider>
  );
};

export default EventBoxContextProvider;
