import { configureStore } from "@reduxjs/toolkit";
import authReducer from "@/redux/reducers/authSlice";
import clientsReducer from "@/redux/reducers/clientsSlice";
import baseReducer from "@/redux/reducers/baseSlice";
import { apiSlice } from "@/redux/api/apiSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    api: apiSlice.reducer,
    clients: clientsReducer,
    base: baseReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
export default store; // export store
