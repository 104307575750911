import { useSelector } from "react-redux";
import { selectPaginationModel } from "@/redux/reducers/baseSlice";
import { selectClients } from "@/redux/reducers/clientsSlice";
import { useGetClientsQuery } from "@/redux/api/clientApiSlice";
import { useEffect } from "react";
import { filterRows } from "@/utils";
import { useSearchParams } from "react-router-dom";
import useLocalStorage from "@/hooks/useLocalStorage";
import { selectCurrentUser } from "@/redux/reducers/authSlice";
import useFilters from "@/hooks/useFilters";

export default function useGetClients(clientsType) {
  const [onlyMy, setOnlyMy] = useLocalStorage("onlyMy", false);
  const currentUser = useSelector(selectCurrentUser);
  // const [params] = useSearchParams();
  // const filters = Object.fromEntries(params);
  const [filters] = useFilters("clientFilters");
  const appendedClients = useSelector(selectClients);
  const { page, pageSize } = useSelector(selectPaginationModel);
  if (onlyMy) {
    filters.assignedTo = currentUser.userId;
  }
  const {
    data: { count: clientsCount, results: clients = [] } = {},
    isLoading: clientsLoading,
    refetch,
    isFetching: clientsIsFetching,
  } = useGetClientsQuery({
    type: clientsType,
    pageSize,
    page: page + 1 === 1 ? null : page + 1,
    ...filters,
  });
  useEffect(() => {
    refetch();
  }, [refetch, page, pageSize]);

  return {
    clients: [...appendedClients, ...filterRows(clients, appendedClients)],
    clientsLoading,
    clientsCount,
    appendedClients,
    refetch,
    clientsIsFetching,
    onlyMy,
    setOnlyMy,
  };
}
