import { useMemo } from "react";
import { Tooltip } from "@mui/material";

export default function useLogsColumns() {
  return useMemo(() => {
    return [
      { field: "id", headerName: "ID", width: 100 },
      {
        field: "actionTime",
        headerName: "Datetime",
        width: 170,
        editable: false,
        type: "dateTime",
        valueGetter: (params) => new Date(params.row?.actionTime),
      },
      {
        field: "actionFlag",
        headerName: "Action",
        editable: false,
      },
      {
        field: "username",
        headerName: "User",
        editable: false,
        renderCell: (params) => {
          return `${params.row.user} - ${params.row.username}`;
        },
      },
      {
        field: "objectId",
        headerName: "Object Id",
        editable: false,
      },
      {
        field: "objectRepr",
        headerName: "Object Description",
        editable: false,
        width: 200,
        renderCell: (params) =>
          `${params.row.contentType} - ${params.row.objectRepr}`,
      },
      {
        field: "changeMessage",
        headerName: "Change Message",
        editable: false,
        flex: 1,
        renderCell: (params) => {
          if (!params.row?.changeMessage) {
            return "";
          }
          return (
            <Tooltip title={params.row?.changeMessage}>
              <span>{params.row?.changeMessage}</span>
            </Tooltip>
          );
        },
      },
    ];
  }, []);
}
