import { canChangeByModel, canDeleteByModel } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CustomMultiSelect, {
  CustomMultiSelectRenderCell,
} from "@/components/MyDataGrid/CustomMultiSelect";
import PasswordIcon from "@mui/icons-material/Password";
import { useMemo } from "react";
import ChangePassword from "@/components/MyDataGrid/StaffDataGrid/ChangePassword";
import DeleteUser from "@/components/MyDataGrid/StaffDataGrid/DeleteUser/DeleteUser";
const usersToSelect = (users) => {
  return users.map((user) => ({
    value: user.id,
    label: user.username,
  }));
};

const groupsToSelect = (groups) => {
  return groups.map((group) => ({
    value: group.id,
    label: group.name,
  }));
};

function useStaffColumns(users, groups) {
  const permissions = useSelector(selectPermissions);

  return useMemo(() => {
    const columns = [
      { field: "id", headerName: "ID", width: 30 },

      {
        field: "username",
        headerName: "Username",
        flex: 1,
        sortable: false,
        editable: false,
      },
      {
        field: "lastLogin",
        headerName: "Last Login",
        flex: 1,
        type: "dateTime",
        sortable: false,
        editable: false,
        valueGetter: (params) => new Date(params.row?.lastLogin),
      },
      {
        field: "groups",
        headerName: "Groups",
        flex: 1,
        editable: false,
        sortable: false,
        type: "singleSelect",
        valueOptions: groupsToSelect(groups),
        renderCell: (params) => {
          return <CustomMultiSelectRenderCell {...params} />;
        },
        renderEditCell: (params) => <CustomMultiSelect {...params} />,
      },
      {
        field: "teamLeader",
        headerName: "Team Lead",
        flex: 1,
        editable: false,
        sortable: false,
        type: "singleSelect",
        valueGetter: (params) => params.row.teamLeader || "",
        valueOptions: usersToSelect(users),
      },
      {
        field: "isActive",
        headerName: "Is Active",
        sortable: false,
        editable: false,
        type: "boolean",
      },
      {
        field: "isOnline",
        headerName: "Is Online",
        sortable: false,
        editable: false,
        type: "boolean",
      },
      {
        field: "isAffiliate",
        headerName: "Is Affiliate",
        sortable: false,
        editable: false,
        type: "boolean",
      },
      {
        field: "allowedIp",
        headerName: "Allowed IP",
        flex: 1,
        sortable: false,
        editable: false,
      },

      {
        field: "actions",
        type: "actions",
        sortable: false,
        width: 80,
        getActions: (params) => [
          <ChangePassword
            key="changePassword"
            userId={params.id}
            icon={<PasswordIcon />}
            label="Change Password"
          />,
          <DeleteUser
            icon={<DeleteOutlineIcon color="error" />}
            label="Delete"
            color="error"
            key="delete"
            userId={params.id}
          />,
        ],
      },
    ];
    return columns.map((column) => {
      if (column.field === "isOnline") return column;
      const newColumn = {
        ...column,
        editable: canChangeByModel("core", "customuser", permissions),
      };
      if (column.field === "actions") {
        return {
          ...newColumn,
          hide: !canDeleteByModel("core", "customuser", permissions),
          editable: false,
        };
      }
      return newColumn;
    });
  }, [groups, permissions, users]);
}

export default useStaffColumns;
