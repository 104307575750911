import { Divider, ListItem, ListItemButton, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useEventBoxContext } from "@/contexts/EventBoxContext";
const getIcon = (isFinished) => {
  if (isFinished) {
    return <CheckCircleIcon fontSize="small" color="success" />;
  }
  return <AccessTimeOutlinedIcon fontSize="small" color="primary" />;
};
const EventBoxListItem = ({ event }) => {
  const { confirmShow } = useEventBoxContext();

  return (
    <>
      <ListItemButton
        onClick={() => confirmShow(event)}
        key={event.id}
        disabled={event?.isFinished}
      >
        <ListItem disableGutters key={event.id}>
          <ListItemText
            primary={
              <span className={"flex items-center space-x-1"}>
                {getIcon(event?.isFinished)}
                <span>
                  {event?.typeName} -
                  {new Date(event.dueDatetime).toLocaleString()}
                </span>
              </span>
            }
            secondary={event.description}
          />
        </ListItem>
      </ListItemButton>
      <Divider component="li" />
    </>
  );
};

export default EventBoxListItem;
