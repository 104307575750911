import DataGridHeader from "@/components/MyDataGrid/DataGridHeader";
import React from "react";
import { useSearchParams } from "react-router-dom";
import useLogsColumns from "./useLogsColumns";
import CustomDataGrid from "@/components/MyDataGrid/CustomDataGrid";
import useGetLogs from "./useGetLogs";
import SkeletonTable from "@/components/skeletons/SkeletonTable";
import UserFilter from "@/components/MyDataGrid/LogsDataGrid/UserFilter";
import DateFilter from "@/components/MyDataGrid/LogsDataGrid/DateFilter";

const LogsDataGrid = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const actionTimeBefore = searchParams.get("actionTimeBefore");
  const actionTimeAfter = searchParams.get("actionTimeAfter");
  const [logs, logsCount, isLoading] = useGetLogs({
    user: userId,
    actionTimeBefore,
    actionTimeAfter,
  });
  const removeSearchParams = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };
  const handleFiltersChange = (key, value) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set(key, value);
      return newSearchParams;
    });
  };
  const columns = useLogsColumns();
  if (isLoading) return <SkeletonTable numberOfRows={5} />;

  return (
    <>
      <DataGridHeader title="Logs">
        <UserFilter
          userId={userId}
          handleFiltersChange={handleFiltersChange}
          removeSearchParams={removeSearchParams}
        />
        <DateFilter
          dates={{
            actionTimeBefore,
            actionTimeAfter,
          }}
          removeSearchParams={removeSearchParams}
          handleFiltersChange={handleFiltersChange}
        />
      </DataGridHeader>
      <CustomDataGrid
        columns={columns}
        rowCount={logsCount || logs.length}
        rows={logs}
        noRowsOverlayText={"No logs found"}
      />
    </>
  );
};

export default LogsDataGrid;
