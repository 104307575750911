import { memo, useState } from "react";
import Modal from "@/components/ui/Modal";
import SetNewPassword from "@/components/MyDataGrid/StaffDataGrid/ChangePassword/SetNewPassword";
import { GridActionsCellItem } from "@mui/x-data-grid";

const ChangePassword = ({ userId, ...rest }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <GridActionsCellItem {...rest} onClick={() => setOpen(true)} />
      <Modal
        maxWidth="xs"
        fullWidth
        title={"Change Password"}
        open={open}
        closeHandler={() => setOpen(false)}
      >
        <SetNewPassword userId={userId} />
      </Modal>
    </>
  );
};

export default memo(ChangePassword);
