import { camelToSnake } from "@/utils/index";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

export const hasPermission = (app, type, perms, field = null) => {
  if (field === null) return perms?.includes(`${app}.${type}`);
  const snake_field = camelToSnake(field);
  return perms?.includes(`${app}.${type}_${snake_field}`);
};

export const canPromoteClient = (perms) => {
  return hasPermission("client", "can_promote", perms);
};
export const canDemoteClient = (perms) => {
  return hasPermission("client", "can_demote", perms);
};
export const canUpdateClientField = (field, perms) => {
  return hasPermission("client", "can_update", perms, field);
};
export const canViewByModel = (app, model, perms) => {
  return hasPermission(app, "view_" + model, perms || []);
};
export const canCreateByModel = (app, model, perms) => {
  return hasPermission(app, "add_" + model, perms || []);
};
export const canDeleteByModel = (app, model, perms) => {
  return hasPermission(app, "delete_" + model, perms || []);
};
export const canChangeByModel = (app, model, perms) => {
  return hasPermission(app, "change_" + model, perms || []);
};
export const useCanCreateByModel = (app, model) => {
  // Цей хук повертає true, якщо користувач має право на створення об'єктів моделі
  const permissions = useSelector(selectPermissions);
  return hasPermission(app, `add_${model}`, permissions || []);
};

export const useCanViewByModel = (app, model) => {
  // Цей хук повертає true, якщо користувач має право на перегляд об'єктів моделі
  const permissions = useSelector(selectPermissions);
  return hasPermission(app, `view_${model}`, permissions || []);
};
export const useCanDeleteByModel = (app, model) => {
  const permissions = useSelector(selectPermissions);
  return hasPermission(app, "delete_" + model, permissions || []);
};
export const useCanChangeByModel = (app, model) => {
  const permissions = useSelector(selectPermissions);
  return hasPermission(app, "change_" + model, permissions || []);
};

export const useCanImportClient = () => {
  const permissions = useSelector(selectPermissions);
  return hasPermission("client", "can_import", permissions || []);
};
export const useCanExportClient = () => {
  const permissions = useSelector(selectPermissions);
  return hasPermission("client", "can_export", permissions || []);
};
