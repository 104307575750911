import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "@/pages/Login";
import Sales from "@/pages/Sales";
import MainLayout from "@/components/layouts/MainLayout/MainLayout";
import Calendar from "@/pages/Calendar";
import Staff from "@/pages/Staff";
import Logs from "@/pages/Logs";
import SingleClient from "@/pages/SingleClient";
import PrivateRoute from "@/routes/PrivateRoute";
import ErrorBoundary from "@/routes/ErrorBoundary";
import Forbidden from "@/pages/Forbidden";
import Groups from "@/pages/Groups";

export const mainNav = [
  {
    path: "",
    title: "Dashboard",
    element: () => <Navigate to="/clients" />,
  },
  {
    title: "Sales",
    path: "/clients",
    app: "client",
    model: "client",
    element: Sales,
  },
  {
    title: "Retentions",
    path: "/retentions",
    app: "client",
    model: "client",
    element: Sales,
  },
  {
    title: "Calendar",
    path: "/calendar",
    app: "planning",
    model: "planning",
    element: Calendar,
  },
  {
    title: "Staff",
    path: "/staff",
    app: "core",
    model: "customuser",
    element: Staff,
  },
  {
    title: "Groups",
    path: "/groups",
    app: "auth",
    model: "group",
    element: Groups,
  },
  {
    title: "Logs",
    path: "/logs",
    app: "admin",
    model: "logentry",
    element: Logs,
  },
];
const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      ...mainNav.map(({ index, path, element, app, model }) => {
        return {
          index: !!index,
          path: path,
          errorElement: <ErrorBoundary />,
          element: <PrivateRoute element={element} app={app} model={model} />,
        };
      }),
      {
        title: "SingleClient",
        path: "/clients/:clientId",
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute element={SingleClient} app="client" model="client" />
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/403",
    element: <Forbidden />,
  },
  {
    path: "*",
    element: <h1>Not Found</h1>,
  },
];

export default createBrowserRouter(routes);
