import { Paper } from "@mui/material";
import { GroupsContextProvider } from "@/contexts/GroupsContext";
import GroupsDeleteModal from "@/pages/Groups/GroupsDeleteModal";
import GroupsAddModal from "@/pages/Groups/GroupsAddModal";
import GroupsDataGrid from "@/components/MyDataGrid/GroupsDataGrid";

const Groups = () => {
  const rows = [
    { id: 1, role: "Admin", permissions: "All" },
    { id: 2, role: "Manager", permissions: "All" },
    { id: 3, role: "Team Lead", permissions: "All" },
    { id: 4, role: "Retention", permissions: "All" },
  ];
  return (
    <GroupsContextProvider>
      <Paper>
        <GroupsDataGrid rows={rows} />
      </Paper>
      <GroupsDeleteModal />
      <GroupsAddModal />
    </GroupsContextProvider>
  );
};

export default Groups;
