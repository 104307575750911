import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Transition from "@/components/ui/Transition";

const ConfirmAlert = ({
  open,
  onAgreeCB,
  onCloseCB,
  title,
  children,
  sx,
  ...rest
}) => {
  const handleAgree = (e) => {
    onAgreeCB(e);
  };
  const handleClose = (e) => {
    onCloseCB(e);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      {...rest}
      sx={{ "& .MuiDialog-container": { alignItems: "flex-start" }, ...sx }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleAgree}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAlert;
