/*
Цей компонент відображає список коментарів до клієнта
 */

import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  useTheme,
} from "@mui/material";
import { useGetCommentsQuery } from "@/redux/api/commentApiSlice";
import { useCommentBoxContext } from "@/contexts/CommentBoxContext";
import { useEffect, useState } from "react";
import CommentBoxListItem from "@/components/comon/CommentBox/CommentBoxListItem";
import { useCanViewByModel } from "@/utils/permission";

const CommentBoxList = () => {
  const theme = useTheme();
  const { addedComments, clientId } = useCommentBoxContext();
  const [pageSize, setPageSize] = useState(10);

  const {
    data: { count, results } = {},
    isLoading,
    isError,
    error,
    refetch,
  } = useGetCommentsQuery({ client: clientId, pageSize });

  useEffect(() => {
    refetch();
  }, [pageSize, refetch]);
  if (isLoading) return <Skeleton variant="rounded" height={400} />;
  if (isError) return <div>{error.data.detail}</div>;

  const finalResults = [...addedComments, ...results]?.sort((a, b) => {
    return new Date(b?.createdAt) - new Date(a?.createdAt);
  });
  const finalCount = count + addedComments.length;
  const loadMoreHandler = () => {
    setPageSize((prev) => prev + 10);
  };
  const isDark = theme.palette.mode === "dark";

  return (
    <>
      <List
        sx={{
          overflow: "auto",
          maxHeight: 415,
          bgcolor: "transparent",
          mt: 2,
        }}
        dense
        disablePadding
      >
        <ListSubheader disableGutters={!isDark}>All comments</ListSubheader>
        {finalResults.length === 0 && (
          <ListItem>
            <ListItemText primary="No comments yet" />
          </ListItem>
        )}
        {finalResults?.map((comment, index) => (
          <CommentBoxListItem comment={comment} key={comment.id} />
        ))}
      </List>
      {finalCount > finalResults.length && (
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={loadMoreHandler}
          sx={{ mt: 2 }}
        >
          Load more
        </Button>
      )}
    </>
  );
};

export default function Index() {
  const canViewComment = useCanViewByModel("comments", "comment");
  if (!canViewComment) {
    return (
      <Alert severity="error" color="warning" sx={{ mt: 2 }}>
        You don't have permission to view comments
      </Alert>
    );
  }
  return <CommentBoxList />;
}
