import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
const isHasFilters = (filters) => {
  return (
    typeof filters === "object" &&
    Object.values(filters).some((value) => !!value)
  );
};
const synchronizeFilters = (key, filters, setParams) => {
  const item = JSON.parse(window.sessionStorage.getItem(key) ?? "{}");

  if (isHasFilters(filters) && !isHasFilters(item)) {
    window.sessionStorage.setItem(key, JSON.stringify(filters));
  }
  if (isHasFilters(item) && !isHasFilters(filters)) {
    setParams(item);
  }
};

const useFilters = (key) => {
  const [params, setParams] = useSearchParams();

  const filters = Object.fromEntries(params);
  useEffect(
    () => synchronizeFilters(key, filters, setParams),
    [setParams, filters, key],
  );

  const setFilters = (value) => {
    try {
      setParams((prev) => ({ ...prev, ...value }));
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  return [filters, setFilters];
};

export default useFilters;
