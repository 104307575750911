import { getCountryNameByCode, getLocalTimeByCountry } from "@/utils";
import { Tooltip, Link as MUILink } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@/redux/reducers/authSlice";
import { canUpdateClientField } from "@/utils/permission";

import { Link } from "react-router-dom";
import ClientActions from "@/components/MyDataGrid/Clients/ClientActions";
import { useClientContext } from "@/contexts/ClientContext";
const canEditMap = (columns, perms) => {
  return columns.map((column) => {
    column.editable = canUpdateClientField(column.field, perms);
    return column;
  });
};

export default function useGetColumns(factory) {
  const user = useSelector(selectCurrentUser);
  const { assignedTo, statuses } = useClientContext();

  return useMemo(() => {
    const columns = [
      {
        field: "actions",
        type: "actions",
        sortable: false,
        getActions: (params) => ClientActions(params),
      },
      {
        field: "id",
        headerName: "ID",
        width: 50,
        sortable: false,
        editable: false,
        renderCell: (params) => (
          <MUILink component={Link} to={`/clients/${params.row.id}`}>
            {params.row.id}
          </MUILink>
        ),
      },
      {
        field: "fullName",
        headerName: "Full Name",
        width: 130,
        editable: false,
        sortable: false,
      },

      {
        field: "createdAt",
        headerName: "Created",
        type: "dateTime",
        width: 130,
        editable: false,
        sortable: false,
        valueGetter: (params) => new Date(params.row?.createdAt),
      },
      {
        field: "localTime",
        headerName: "Local Time",
        type: "text",
        width: 160,
        editable: false,
        sortable: false,
        valueGetter: (params) =>
          `${
            params.row.country ? getLocalTimeByCountry(params.row.country) : "–"
          }`,
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 130,
        sortable: false,
        editable: true,
      },
      {
        field: "email2",
        headerName: "E-mail2",
        width: 130,
        type: "email",
        sortable: false,
        editable: true,
      },
      {
        field: "phone",
        headerName: "Phone",
        width: 130,
        type: "phone",
        sortable: false,
        editable: true,
      },
      {
        field: "phone2",
        headerName: "Phone2",
        width: 130,
        type: "phone",
        sortable: false,
        editable: true,
      },
      {
        field: "country",
        headerName: "Country",
        width: 130,
        sortable: false,
        editable: false,
        valueGetter: (params) =>
          getCountryNameByCode(params.row.country) || "–",
      },
      {
        field: "source",
        headerName: "Source",
        sortable: false,
        width: 100,
        editable: false,
      },
      {
        field: "assignedTo",
        headerName: "Assigned to",
        width: 130,
        type: "singleSelect",
        valueOptions: assignedTo?.map((user) => ({
          label: user.username,
          value: user.id,
        })) || [{ label: "–", value: "" }],
        editable: true,
        sortable: false,
        valueGetter: (params) => params.row.assignedTo || "–",
      },
      {
        field: "status",
        headerName: "Status",
        width: 130,
        type: "singleSelect",
        sortable: false,
        valueOptions:
          statuses?.map(({ name, id }) => ({
            label: name,
            value: id,
          })) || [],
        editable: true,
        valueGetter: (params) => {
          return params.row.status || "–";
        },
      },
      {
        field: "lastComment",
        headerName: "Last Comment",
        editable: false,
        sortable: false,
        minWidth: 160,
        renderCell: (params) => (
          <Tooltip title={params.row.lastCommentText}>
            <span>
              {params.row.lastComment
                ? new Date(params.row.lastComment).toLocaleString()
                : "–"}
            </span>
          </Tooltip>
        ),
      },
    ];

    if (user.role === "admin") {
      return columns;
    }

    const filteredColumns = columns.filter((column) => {
      if (column.field === "assignedTo") {
        return canUpdateClientField("assignedTo", user?.permissions || []);
      }
      return true;
    });

    return canEditMap(filteredColumns, user?.permissions || []);
  }, [assignedTo, statuses, user.role, user?.permissions]);
}
