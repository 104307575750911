import { Link } from "react-router-dom";
import MainNav from "./MainNav";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import ThemeSwitcher from "./ThemeSwitcher";
// import NotifyButton from "./NotifyButton";
import LogoutButton from "@/components/comon/LogoutButton";
import { lazy } from "react";
const FlippingClientButtons = lazy(() => import("./FlippingClientButtons"));

const MainHeader = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const themeColor = isDark
    ? theme.palette.grey["600"]
    : theme.palette.grey["200"];
  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        borderBottom: "1px solid",
        borderColor: themeColor,
        top: -1,
      }}
      elevation={0}
    >
      <Container as="nav" maxWidth={false} className="py-2 sm:py-0">
        <Toolbar>
          <div className="me-5 md:me-8">
            <Link to="/" aria-label="CRM">
              <Typography variant="h6" component="h2">
                CRM
              </Typography>
            </Link>
          </div>
          <MainNav className={"hidden md:block"} />
          <div className="flex items-center justify-end ms-auto sm:justify-between sm:gap-x-3 sm:order-3">
            <div className="flex flex-row items-center justify-end gap-2">
              <FlippingClientButtons />
              <ThemeSwitcher />
              {/*<NotifyButton />*/}
              <LogoutButton />
            </div>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MainHeader;
