import { Divider, ListItem, ListItemText } from "@mui/material";

const CommentBoxListItem = ({ comment }) => {
  return (
    <>
      <ListItem disableGutters key={comment.id}>
        <ListItemText
          primary={`${comment.createdByUsername} - ${new Date(
            comment.createdAt,
          ).toLocaleString()}`}
          secondary={comment.comment}
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default CommentBoxListItem;
