import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  useTheme,
} from "@mui/material";
import EventBoxListItem from "./EventBoxListItem";
import { useGetPlanningsQuery } from "@/redux/api/planningApiSlice";
import { useCanViewByModel } from "@/utils/permission";
import { useEventBoxContext } from "@/contexts/EventBoxContext";
import { useState } from "react";
import { sortEventsByDue } from "@/utils";

const mergeEventsWithOutDuplicates = (addedEvents, results) => {
  // Merge events with out duplicates
  const finalResults = [...addedEvents, ...results];
  const finalResultsIds = finalResults.map((event) => event.id);
  return finalResults.filter(
    (event, index) => finalResultsIds.indexOf(event.id) === index,
  );
};

const EventBoxList = () => {
  const { clientId, addedEvents } = useEventBoxContext();
  const [pageSize, setPageSize] = useState(10);
  const {
    data: { count, results } = {},
    isLoading,
    isError,
    error,
  } = useGetPlanningsQuery({ client: clientId, pageSize: pageSize });
  const theme = useTheme();
  if (isLoading) return <Skeleton variant="rounded" height={400} />;
  if (isError) return <div>{error.data.detail}</div>;
  const isDark = theme.palette.mode === "dark";
  const finalResults = mergeEventsWithOutDuplicates(addedEvents, results);

  const finalCount = count + addedEvents.length;
  const loadMoreHandler = () => {
    setPageSize((prev) => prev + 10);
  };
  return (
    <>
      <List
        sx={{
          overflow: "auto",
          maxHeight: 350,
          bgcolor: "transparent",
          mt: 2,
        }}
        dense
        disablePadding
      >
        <ListSubheader disableGutters={!isDark}>All events</ListSubheader>
        {finalResults.length === 0 && (
          <ListItem>
            <ListItemText primary="Events will appear here" />
          </ListItem>
        )}
        {sortEventsByDue(finalResults, false).map((event) => (
          <EventBoxListItem event={event} key={event.id} />
        ))}
      </List>
      {finalCount > finalResults.length && (
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={loadMoreHandler}
          sx={{ mt: 2 }}
        >
          Load more
        </Button>
      )}
    </>
  );
};

export default function Index() {
  const canView = useCanViewByModel("planning", "planning");
  if (!canView) {
    return (
      <Alert severity="error" color="warning" sx={{ mt: 2 }}>
        You don't have permission to view events
      </Alert>
    );
  }
  return <EventBoxList />;
}
