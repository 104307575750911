import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import moment from "moment-timezone";
import iso3311a2 from "iso-3166-1-alpha-2";
import { Fragment } from "react";
export function cn(...inputs) {
  return twMerge(classNames(inputs));
}

export function getLocalTimeByCountry(country) {
  if (!country) return;
  const now = new Date();
  // const countryCode = iso3311a2.getCode(country);
  const timezone = moment.tz.zonesForCountry(country)[0];
  return moment(now).tz(timezone).format("DD.MM.YYYY HH:mm");
}

export function getCountryNameByCode(code) {
  if (!code) return code;
  return iso3311a2?.getCountry(code);
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}
export const sortEventsByDue = (events, order_by = true) => {
  if (order_by) {
    return events.sort((a, b) => {
      return new Date(b.dueDatetime) - new Date(a.dueDatetime);
    });
  } else {
    return events.sort((a, b) => {
      return new Date(a.dueDatetime) - new Date(b.dueDatetime);
    });
  }
};

export function formatDate(date) {
  return moment(date).format("DD.MM.YYYY");
}

export function dateTimeToTime(date) {
  return moment(date).format("HH:mm");
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function generateRandomData() {
  const types = ["Call", "Email", "Meeting"];
  const statuses = ["New", "Finished"];
  const data = [];
  const maxIterations = getRandomInt(10);
  const textOrNull = (text) => (getRandomInt(2) ? text : null);
  for (let i = 0; i < maxIterations; i++) {
    data.push({
      dateTime: new Date(),
      client: "John Doe", // TODO: "John Doe" should be replaced with a real client name
      id: i + 1,
      text: textOrNull(
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ultricies lorem vitae lacus accumsan ornare. Maecenas tincidunt, elit a rhoncus aliquet, nibh tortor placerat orci, in lobortis eros purus ut dui",
      ),
      status: statuses[getRandomInt(statuses.length)],
      type: types[getRandomInt(types.length)],
    });
  }
  return data;
}

export function convertKeysToCamelCase(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  }

  const camelCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase(),
      );
      const value = obj[key];
      camelCaseObj[camelCaseKey] = convertKeysToCamelCase(value);
    }
  }
  return camelCaseObj;
}

export function camelToSnake(str) {
  return str.replace(/[A-Z]/g, (match) => "_" + match.toLowerCase());
}

export const JSONtoCSVFile = (arr, columns, delimiter = ",") => {
  const header = columns.join(delimiter) + "\n";
  const csv = arr.map((row) => {
    return columns
      .map((fieldName) => {
        const value = row[fieldName];
        if (value === null || value === undefined) {
          return "";
        }
        return value;
      })
      .join(delimiter);
  });
  csv.unshift(header);
  return csv.join("\n");
};

export async function exportToCsv(filename, rows) {
  const processRow = function (row) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };
  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const onEnterPress = (e, cb) => {
  if (e.keyCode === 13 && e.shiftKey === true) {
    e.preventDefault();
    cb(e);
  }
};

export const parseError = (errors) => {
  return (
    <>
      {errors?.map((error, index) => (
        <Fragment key={index}>
          {error}
          <br />
        </Fragment>
      ))}
    </>
  );
};

export const filterRows = (rows, data) => {
  return rows.filter((row) => {
    return !data.some((value) => value.id === row.id);
  });
};
