import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useClientContext } from "@/contexts/ClientContext";
import { canUpdateClientField } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

const AssignedToFilter = ({ value, setSearchValue, onClearInput }) => {
  const { assignedTo } = useClientContext();
  const perms = useSelector(selectPermissions);
  const canAssign = canUpdateClientField("assigned_to", perms);
  if (!canAssign) {
    return null;
  }
  return (
    <Autocomplete
      disablePortal
      options={assignedTo || []}
      onChange={(e, value) => {
        setSearchValue((prev) => ({ ...prev, assignedTo: value?.id }));
      }}
      sx={{ width: 200 }}
      value={assignedTo.find((option) => option.id === parseInt(value)) || null}
      getOptionLabel={(option) => option.username}
      getOptionKey={(option) => option.id}
      componentsProps={{
        clearIndicator: {
          onClick: () => {
            onClearInput("assignedTo");
          },
        },
      }}
      renderInput={({ sx, ...params }) => {
        return (
          <TextField
            variant="standard"
            {...params}
            size="small"
            sx={{ paddingTop: "0", ...sx }}
            name={"assignedTo"}
            placeholder={"Assigned to"}
          />
        );
      }}
    />
  );
};

export default React.memo(AssignedToFilter);
