import { createSlice } from "@reduxjs/toolkit";

const baseSlice = createSlice({
  name: "base",
  initialState: {
    filters: {},
    paginationModel: {
      pageSize: parseInt(localStorage.getItem("pageSize")) || 20,
      page: 0,
    },
    snackbar: {
      open: false,
      message: "",
      severity: "success",
    },
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setPaginationModel(state, action) {
      state.paginationModel = action.payload;
      localStorage.setItem("pageSize", action.payload.pageSize);
    },
    setPageSize(state, action) {
      state.paginationModel.pageSize = parseInt(action.payload);
    },
    setPage(state, action) {
      state.paginationModel.page = parseInt(action.payload);
    },
    setSnackbar(state, action) {
      state.snackbar = action.payload;
    },
  },
});

export const selectFilters = (state) => state.base.filters;
export const selectIsSearch = (state) => !!state.base.search;
export const selectPaginationModel = (state) => state.base.paginationModel;
export const selectSnackbar = (state) => state.base.snackbar;
export const {
  setFilters,
  setSnackbar,
  setPaginationModel,
  setPage,
  setPageSize,
} = baseSlice.actions;
export default baseSlice.reducer;
