import { useCanCreateByModel } from "@/utils/permission";
import { Alert, Fab, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useCommentBoxContext } from "@/contexts/CommentBoxContext";
import { useSetSnackBar } from "@/hooks";
import { Add } from "@mui/icons-material";
import { onEnterPress } from "@/utils";

const CommentBoxAdd = () => {
  const [comment, setComment] = useState("");
  const { addComment, errors, setErrors, clientId } = useCommentBoxContext();
  const snackbar = useSetSnackBar();

  const handleAddComment = (e) => {
    e.preventDefault();
    if (!comment) {
      snackbar("Comment is required", "warning");
      return;
    }
    addComment({ comment, client: clientId });
    setComment("");
  };

  const handleCommentChange = useCallback(
    (e) => {
      setComment(e.target.value);
      if (errors?.comment) {
        setErrors((prev) => ({ ...prev, comment: null }));
      }
    },
    [errors, setErrors],
  );

  return (
    <form onSubmit={handleAddComment} className={"relative"}>
      <TextField
        error={!!errors?.comment}
        label="Add comment"
        helperText={errors?.comment || "Can shift+enter to add comment"}
        multiline
        rows={4}
        value={comment}
        onChange={handleCommentChange}
        fullWidth
        onKeyDown={(e) => onEnterPress(e, handleAddComment)}
      />
      <Fab
        type={"submit"}
        color="primary"
        aria-label="add"
        size="small"
        sx={{ position: "absolute", right: 10, bottom: 5 }}
      >
        <Add />
      </Fab>
    </form>
  );
};

export default function Index() {
  const canAddComment = useCanCreateByModel("comments", "comment");
  if (!canAddComment) {
    return (
      <Alert severity="error" color="warning">
        You don't have permission to add comment
      </Alert>
    );
  }
  return <CommentBoxAdd />;
}
