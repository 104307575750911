import { apiSlice } from "@/redux/api/apiSlice";

export const clientsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: (params) => {
        // створюємо новий об'єкт з параметрами для запиту без порожніх значень
        params = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value),
        );
        return {
          url: "/clients/",
          params,
        };
      },
    }),
    getClientsStatuses: builder.query({
      query: () => "/clients/statuses/",
    }),
    getClient: builder.query({
      query: (id) => `/clients/${id}/`,
    }),

    getClientsCountries: builder.query({
      query: () => "/clients/countries/",
    }),

    updateClient: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/clients/${id}/`,
        method: "PUT",
        body: data,
      }),
    }),
    createClient: builder.mutation({
      query: (data) => ({
        url: "/clients/",
        method: "POST",
        body: data,
      }),
    }),
    massAssignClients: builder.mutation({
      query: (data) => ({
        url: "/clients/mass_assign_to_user/",
        method: "POST",
        body: data,
      }),
    }),
    massDeleteClients: builder.mutation({
      query: (data) => ({
        url: "/clients/mass_delete/",
        method: "POST",
        body: data,
      }),
    }),
    importClientsFormCSV: builder.mutation({
      query: (data) => ({
        url: "/clients/import_from_csv/",
        method: "POST",
        body: data,
        formData: true,
      }),
    }),
    exportClientsToJSON: builder.mutation({
      query: (data) => ({
        url: "/clients/export_to_json/",
      }),
    }),
    promoteClient: builder.mutation({
      query: (clients) => ({
        url: `/clients/promote/`,
        method: "POST",
        body: { clients },
      }),
    }),
    demoteClient: builder.mutation({
      query: (clients) => ({
        url: `/clients/demote/`,
        method: "POST",
        body: { clients },
      }),
    }),
    flipClient: builder.mutation({
      query: ({ id, type, ...filters }) => ({
        url: `/clients/${id}/${type}/`,
        params: filters,
      }),
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useGetClientsStatusesQuery,
  useUpdateClientMutation,
  useCreateClientMutation,
  useMassAssignClientsMutation,
  useMassDeleteClientsMutation,
  useImportClientsFormCSVMutation,
  useExportClientsToJSONMutation,
  useGetClientsCountriesQuery,
  useFlipClientMutation,
  usePromoteClientMutation,
  useDemoteClientMutation,
} = clientsApiSlice;
