import { useCallback } from "react";
import { useSetSnackBar } from "@/hooks";
import { useDispatch } from "react-redux";

export default function useRowProcessUpdate(
  serverUpdateFunc,
  appendToStoreFunc = null,
) {
  const dispatch = useDispatch();
  const snackBar = useSetSnackBar();
  return useCallback(
    async (newRow, oldRow, editCellRef) => {
      if (
        typeof newRow[editCellRef.current] != "boolean" &&
        !newRow[editCellRef.current]
      )
        return newRow;
      if (oldRow[editCellRef.current] === newRow[editCellRef.current]) {
        return newRow;
      }
      const data = {
        id: newRow.id,
        [editCellRef.current]: newRow[editCellRef.current],
      };
      const response = await serverUpdateFunc(data).unwrap();
      snackBar(
        `Field "${editCellRef.current}" updated successfully!`,
        "success",
        true,
      );
      editCellRef.current = null;
      if (appendToStoreFunc) {
        dispatch(appendToStoreFunc(response));
      }
      return response;
    },
    [snackBar, serverUpdateFunc, dispatch, appendToStoreFunc],
  );
}
