import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSelectedClientsIds } from "@/redux/reducers/clientsSlice";
import { selectPermissions } from "@/redux/reducers/authSlice";
import { canUpdateClientField } from "@/utils/permission";
import { useState } from "react";
import AssignedToForm from "./AssignedToForm";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

const AssignedTo = () => {
  const selectedClient = useSelector(selectSelectedClientsIds);
  const perms = useSelector(selectPermissions);
  const canAssign = canUpdateClientField("assigned_to", perms);
  const [open, setOpen] = useState(false);

  if (!canAssign || selectedClient.length === 0) {
    return null;
  }

  return (
    <div>
      <Button
        sx={{ px: 2 }}
        onClick={() => setOpen(true)}
        endIcon={<PersonAddAltIcon />}
      >
        Assign
      </Button>
      <AssignedToForm
        open={open}
        setOpen={setOpen}
        selectedClient={selectedClient}
      />
    </div>
  );
};

export default AssignedTo;
