import { useClientsDialogContext } from "@/contexts/ClientsDialogContext";
import Modal from "@/components/ui/Modal";
import CommentBox from "@/components/comon/CommentBox/CommentBox";

const ClientsCommentsDialog = () => {
  const { open, toggleDialog, clientId } = useClientsDialogContext();
  if (clientId === null) return null;
  return (
    <Modal
      closeHandler={() => toggleDialog(null)}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <CommentBox clientId={clientId} />
    </Modal>
  );
};

export default ClientsCommentsDialog;
