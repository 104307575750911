/**
 *
 */
import { apiSlice } from "@/redux/api/apiSlice";

export const commentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Отримання списку коментарів з можливістю фільтрації по client та created_by
    getComments: builder.query({
      query: (params) => {
        params = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value),
        );
        return {
          url: "/comments/",
          params,
        };
      },
    }),
    // Отримання коментарія по id
    getComment: builder.query({
      query: (id) => `/comments/${id}/`,
    }),
    // Додавання нового коментарія
    createComment: builder.mutation({
      query: (data) => {
        console.log(data);
        return {
          url: "/comments/",
          method: "POST",
          body: data,
        };
      },
    }),
    // Видалення коментарія по id
    deleteComment: builder.mutation({
      query: (id) => ({
        url: `/comments/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useGetCommentQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
} = commentApiSlice;
