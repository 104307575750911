import { apiSlice } from "@/redux/api/apiSlice";

export const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogs: builder.query({
      query: (params) => {
        params = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value),
        );
        return {
          url: "/logs/",
          params,
        };
      },
    }),
  }),
});

export const { useGetLogsQuery } = logsApiSlice;
