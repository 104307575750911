import { NavLink, useLocation } from "react-router-dom";
import { MenuItem } from "@mui/material";

const MainNavItem = ({ path, title }) => {
  const location = useLocation();
  const isSelected = location.pathname === path;

  return (
    <li>
      <MenuItem component={NavLink} to={path} selected={isSelected}>
        {title}
      </MenuItem>
    </li>
  );
};

export default MainNavItem;
