import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useGetUsersQuery } from "@/redux/api/usersApiSlice";
import { useGetGroupsQuery } from "@/redux/api/groupApiSlice";
import { selectPaginationModel } from "@/redux/reducers/baseSlice";
import { useSelector } from "react-redux";

const StaffContext = createContext(null);

export const useStaffContext = () => {
  return useContext(StaffContext);
};

const StaffContextProvider = ({ children }) => {
  const apiRef = useRef(null);
  const { page, pageSize } = useSelector(selectPaginationModel);
  const {
    data: { results, count } = {},
    isLoading: isLoadingStaff,
    refetch,
  } = useGetUsersQuery({ pageSize, page: page + 1 });
  const { data: { results: groups } = {}, isLoading: isLoadingGroups } =
    useGetGroupsQuery({ pageSize: 100 });

  const [addedStaff, setAddedStaff] = useState([]);
  const addStaff = (staff) => {
    setAddedStaff((prev) => [...prev, staff]);
  };

  apiRef.current = {
    addStaff,
    refetch,
  };
  const value = {
    staff: [...addedStaff, ...(results || [])],
    staffCount: count,
    isLoadingStaff,
    isLoadingGroups,
    groups,
    apiRef,
  };

  useEffect(() => {
    refetch();
  }, [page, pageSize, refetch]);
  return (
    <StaffContext.Provider value={value}>{children}</StaffContext.Provider>
  );
};

export default StaffContextProvider;
