import { createContext, useContext, useRef } from "react";

import useGetClients from "@/hooks/useGetClients";
import { useGetAssignedToListQuery } from "@/redux/api/usersApiSlice";
import { useGetClientsStatusesQuery } from "@/redux/api/clientApiSlice";

const ClientContext = createContext(null);
export const useClientContext = () => {
  return useContext(ClientContext);
};

export const ClientContextProvider = ({ children, clientsType }) => {
  const clientsApiRef = useRef(null);
  const {
    clients,
    refetch,
    clientsCount,
    clientsLoading,
    clientsIsFetching,
    onlyMy,
    setOnlyMy,
  } = useGetClients(clientsType);

  const { data: assignedTo } = useGetAssignedToListQuery();
  const { data: statuses } = useGetClientsStatusesQuery();

  clientsApiRef.current = { refetch, setOnlyMy };

  return (
    <ClientContext.Provider
      value={{
        clients,
        clientsApiRef,
        clientsCount,
        clientsLoading,
        clientsIsFetching,
        statuses,
        assignedTo,
        onlyMy,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
