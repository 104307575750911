import { useCanCreateByModel } from "@/utils/permission";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import AddStaffForm from "@/components/MyDataGrid/StaffDataGrid/AddStaff/AddStaffForm";
import * as React from "react";
const AddStaff = () => {
  const canCreate = useCanCreateByModel("core", "customuser");
  const [open, setOpen] = useState(false);
  if (!canCreate) return null;
  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<Add fontSize="small" />}
      >
        Add
      </Button>
      <AddStaffForm open={open} setOpen={setOpen} />
    </>
  );
};

export default React.memo(AddStaff);
