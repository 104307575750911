import { Box, Skeleton, Typography } from "@mui/material";
import { useGetClientQuery } from "@/redux/api/clientApiSlice";
import SingleClientInfoForm from "./SingleClientInfoForm";
import PromoteClient from "@/pages/SingleClient/SingleClientInfo/PromoteClient";

const SingleClientInfo = ({ clientId }) => {
  const { data: { createdAt, updatedAt, ...client } = {}, isLoading } =
    useGetClientQuery(clientId);

  if (isLoading) return <Skeleton variant="rounded" height={400} />;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" component={"h2"}>
          Client id {clientId}
        </Typography>
        <PromoteClient type={client.type} clientId={client.id} />
      </Box>
      <Typography component={"p"} variant="caption" sx={{ mb: 1 }}>
        Created: {new Date(createdAt).toLocaleString()}
      </Typography>
      <Typography component={"p"} variant="caption" sx={{ mb: 2 }}>
        Updated: {new Date(updatedAt).toLocaleString()}
      </Typography>
      {isLoading ? (
        <Skeleton variant="rounded" height={400} />
      ) : (
        <SingleClientInfoForm client={client} />
      )}
    </>
  );
};

export default SingleClientInfo;
