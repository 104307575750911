import Clients from "@/components/MyDataGrid/Clients";
import { Paper } from "@mui/material";
import ClientsHeader from "@/components/MyDataGrid/Clients/ClientsHeader";

import { memo } from "react";
import { useGetClientsType } from "@/hooks";
import { ClientContextProvider } from "@/contexts/ClientContext";

const Sales = () => {
  const { clientsType, title } = useGetClientsType();
  return (
    <Paper>
      <ClientContextProvider clientsType={clientsType}>
        <ClientsHeader title={title} clientsType={clientsType} />
        <Clients />
      </ClientContextProvider>
    </Paper>
  );
};

export default memo(Sales);
