import { createSlice } from "@reduxjs/toolkit";

const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    clients: [],
    selectedClientsIds: [],
    usersSelect: [],
    statuses: [],
    isRefetch: false,
  },
  reducers: {
    appendClients: (state, action) => {
      state.clients = state.clients.concat(action.payload);
    },
    appendClient: (state, action) => {
      state.clients.push(action.payload);
    },
    setSelectedClientsIds: (state, action) => {
      state.selectedClientsIds = action.payload;
    },
    setUsersSelect: (state, action) => {
      state.usersSelect = action.payload;
    },
    toggleIsRefetch: (state) => {
      state.isRefetch = !state.isRefetch;
    },
  },
});

export const selectSelectedClientsIds = (state) =>
  state.clients.selectedClientsIds;

export const selectIsRefetch = (state) => state.clients.isRefetch;
export const selectUsersSelect = (state) => state.clients.usersSelect;
export const selectClients = (state) => state.clients.clients;
export const {
  appendClients,
  appendClient,
  setSelectedClientsIds,
  setUsersSelect,
  toggleIsRefetch,
} = clientsSlice.actions;

export default clientsSlice.reducer;
