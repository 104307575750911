/**
 * Поля форми для відображення інформації про клієнта
 * @name clientFields
 * @type {[{name: string, label: string},{name: string, label: string},{name: string, label: string},{name: string, label: string},{name: string, label: string},null,null,null]}
 */

const clientFields = [
  {
    name: "firstName",
    label: "First name",
  },
  {
    name: "lastName",
    label: "Last name",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "email2",
    label: "Email 2",
  },
  {
    name: "phone",
    label: "Phone",
  },
  {
    name: "phone2",
    label: "Phone2",
  },
  {
    name: "country",
    label: "Country",
  },
  {
    name: "source",
    label: "Source",
  },
];
export default clientFields;
