import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { Typography } from "@mui/material";

const ErrorBoundary = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error) || error.status !== 200) {
    return (
      <>
        <Typography variant="h1" component={"h1"}>
          {error.status}
        </Typography>
        <Typography variant="h2" component={"h2"}>
          {error?.data?.detail}
        </Typography>
      </>
    );
  }
  throw error;
};

export default ErrorBoundary;
