import DataGridHeader from "@/components/MyDataGrid/DataGridHeader";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useGroupsContext } from "@/contexts/GroupsContext";
import CustomDataGrid from "@/components/MyDataGrid/CustomDataGrid";
import useGroupColumns from "@/components/MyDataGrid/GroupsDataGrid/useGroupColumns";
import { memo } from "react";
import useRowProcessUpdate from "@/components/MyDataGrid/CustomDataGrid/useRowProcessUpdate";
import SkeletonTable from "@/components/skeletons/SkeletonTable";
import * as React from "react";
const GroupsDataGrid = () => {
  const { groupApiRef, groups, gropusCount, isLoading, allPermissions } =
    useGroupsContext();
  const processRowUpdate = useRowProcessUpdate(groupApiRef.current.updateGroup);
  const columns = useGroupColumns(groupApiRef, allPermissions);
  if (isLoading) return <SkeletonTable numberOfRows={10} />;

  return (
    <>
      <DataGridHeader title="Groups">
        <Button
          variant="contained"
          onClick={() => groupApiRef.current.openModal(null, "add")}
          startIcon={<Add />}
        >
          Add Role
        </Button>
      </DataGridHeader>
      <CustomDataGrid
        rowCount={gropusCount || 0}
        columns={columns}
        rows={groups || []}
        disableColumnMenu
        disableRowSelectionOnClick
        noRowsOverlayText={"No Groups"}
        processRowHandler={processRowUpdate}
      />
    </>
  );
};

export default memo(GroupsDataGrid);
