import { Typography } from "@mui/material";
import EventBoxAdd from "./EventBoxAdd";
import EventBoxList from "./EventBoxList";
import EventBoxContextProvider from "@/contexts/EventBoxContext";
import EventBoxConfirm from "@/components/comon/EventBox/EventBoxConfirm";

const EventBox = ({ clientId }) => {
  return (
    <EventBoxContextProvider clientId={clientId}>
      <Typography variant="h6" component={"h2"} sx={{ mb: 1 }}>
        Events
      </Typography>
      <EventBoxAdd />
      <EventBoxList />
      <EventBoxConfirm />
    </EventBoxContextProvider>
  );
};

export default EventBox;
