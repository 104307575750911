import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useClientContext } from "@/contexts/ClientContext";

const StatusFilter = ({ value, setSearchValue, onClearInput }) => {
  const { statuses } = useClientContext();

  return (
    <Autocomplete
      disablePortal
      options={statuses || []}
      onChange={(e, value) => {
        setSearchValue((prev) => ({ ...prev, status: value?.id }));
      }}
      sx={{ width: 200 }}
      value={statuses.find((option) => option.id === parseInt(value)) || null}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      componentsProps={{
        clearIndicator: {
          onClick: () => {
            onClearInput("status");
          },
        },
      }}
      renderInput={({ sx, ...params }) => {
        return (
          <TextField
            variant="standard"
            {...params}
            size="small"
            sx={{ paddingTop: "0", ...sx }}
            name={"status"}
            placeholder={"Status"}
          />
        );
      }}
    />
  );
};

export default React.memo(StatusFilter);
