/*
Цей файл створює контекст для CommentBox компоненту.
 */

import { createContext, useContext, useState } from "react";
import { useCreateCommentMutation } from "@/redux/api/commentApiSlice";
import { useSetSnackBar } from "@/hooks";

const CommentBoxContext = createContext({
  addedComments: [],
  addComment: () => {},
  errors: {},
  setErrors: () => {},
  clientId: null,
});

export const useCommentBoxContext = () => {
  return useContext(CommentBoxContext);
};

const CommentBoxContextProvider = ({ children, clientId }) => {
  const [comments, setComments] = useState([]);
  const [errors, setErrors] = useState({});
  const [createComment] = useCreateCommentMutation();
  const snackbar = useSetSnackBar();
  const addComment = async (comment) => {
    try {
      const response = await createComment(comment).unwrap();
      setComments((prev) => [...prev, response]);
      snackbar("Comment added", "success");
    } catch (error) {
      if (error.data?.detail) {
        snackbar(error.data.detail, "error");
      } else {
        setErrors(error.data || {});
      }
    }
  };
  return (
    <CommentBoxContext.Provider
      value={{
        addedComments: comments,
        addComment: addComment,
        errors: errors,
        setErrors: setErrors,
        clientId: clientId,
      }}
    >
      {children}
    </CommentBoxContext.Provider>
  );
};
export default CommentBoxContextProvider;
