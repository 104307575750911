import { GridToolbarContainer } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { memo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SearchInput from "./SearchInput";
import CountryFilter from "./CountryFilter";
import AssignedToFilter from "./AssignedToFilter";
import StatusFilter from "./StatusFilter";
import useFilters from "@/hooks/useFilters";
const deleteEmptyKeys = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

function CustomSearchToolbar() {
  // const [params, setParams] = useSearchParams();
  // const filters = Object.fromEntries(params);
  const [filters, setParams] = useFilters("clientFilters");
  const [filtersValue, setFiltersValue] = useState(filters || {});

  const onChange = (e) => {
    setFiltersValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onClearInput = (name) => {
    setFiltersValue((prev) => {
      const newFilters = { ...prev };
      delete newFilters[name];
      setParams(newFilters);
      return newFilters;
    });
  };

  const canAction = Object.values(filtersValue || {}).some((value) => !!value);
  const handleSearch = (e) => {
    e.preventDefault();
    setParams(deleteEmptyKeys(filtersValue));
  };
  const handleClear = () => {
    setParams({});
    setFiltersValue(null);
  };
  return (
    <GridToolbarContainer>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box
          as={"form"}
          onSubmit={handleSearch}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <SearchInput
            value={filtersValue?.search}
            onChange={onChange}
            onClearInput={onClearInput}
          />
          <CountryFilter
            value={filtersValue?.country}
            setSearchValue={setFiltersValue}
            onClearInput={onClearInput}
          />
          <AssignedToFilter
            value={filtersValue?.assignedTo}
            setSearchValue={setFiltersValue}
            onClearInput={onClearInput}
          />
          <StatusFilter
            value={filtersValue?.status}
            setSearchValue={setFiltersValue}
            onClearInput={onClearInput}
          />
          {canAction && (
            <>
              <IconButton type="submit" size="small">
                <SearchIcon fontSize="inherit" />
              </IconButton>
              <IconButton onClick={handleClear} size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}

export default memo(CustomSearchToolbar);
