import Modal from "@/components/ui/Modal";
import { Button, Typography } from "@mui/material";

const DeleteClientsConfirm = ({ open, setOpen, count, deleteHandler }) => {
  return (
    <Modal
      open={open}
      closeHandler={() => setOpen(false)}
      title={`Are you sure you want to delete these ${count} clients?`}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <Typography variant={"subtitle1"} component={"p"}>
        This action cannot be undone.
      </Typography>
      <div className={"mt-5 flex items-center  space-x-3"}>
        <Button
          onClick={deleteHandler}
          variant="contained"
          color="error"
          type="submit"
          disableElevation
          size="large"
        >
          Delete
        </Button>
        <Button variant="text" size="large" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteClientsConfirm;
