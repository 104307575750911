import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordField = ({ inputProps, error, helpText, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl variant="outlined" {...props}>
      <InputLabel error={error}>Password</InputLabel>
      <OutlinedInput
        error={error}
        type={showPassword ? "text" : "password"}
        {...inputProps}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              color={error ? "error" : "primary"}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
      {helpText && <FormHelperText error={error}>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default PasswordField;
