/**
 * Цей файл містить логіку для взаємодії з API для отримання даних про користувачів
 */

// Підключаємо необхідні бібліотеки
import { apiSlice } from "@/redux/api/apiSlice";

// Створюємо новий slice для взаємодії з API
export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Отримання списку користувачів
    getUsers: builder.query({
      query: (params) => ({
        url: "/users/",
        params,
      }),
    }),
    // Отримання даних про конкретного користувача
    getUser: builder.query({
      query: (id) => `/users/${id}/`,
    }),
    createUsers: builder.mutation({
      query: (data) => ({
        url: "/users/",
        method: "POST",
        body: data,
      }),
    }),

    // Оновлення даних про користувача
    updateUser: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/${id}/`,
        method: "PUT",
        body: data,
      }),
    }),
    // Видалення користувача
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}/`,
        method: "DELETE",
      }),
    }),
    // Отримання списку користувачів, які можуть бути призначені
    getAssignedTo: builder.mutation({
      query: () => "/users/assigned_to_list/",
    }),
    // Отримання списку користувачів, які можуть бути призначені
    getAssignedToList: builder.query({
      query: () => "/users/assigned_to_list/",
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetAssignedToMutation,
  useGetAssignedToListQuery,
  useCreateUsersMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApiSlice;
