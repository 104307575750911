import { Paper } from "@mui/material";
import LogsDataGrid from "@/components/MyDataGrid/LogsDataGrid";

const Logs = () => {
  return (
    <Paper>
      <LogsDataGrid />
    </Paper>
  );
};

export default Logs;
