import { Autocomplete, TextField } from "@mui/material";
import { useMemo, useState } from "react";

const TeamLeaderAutocomplete = ({ form, setForm, staff, ...rest }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      {...rest}
      value={form?.teamLeader || null}
      name="teamLeader"
      onChange={(e, value) => {
        setForm((prevState) => ({ ...prevState, teamLeader: value }));
      }}
      renderInput={(params) => (
        <TextField {...params} label="Team leader" fullWidth />
      )}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={staff?.map((staff) => {
        return staff.id;
      })}
      getOptionLabel={(option) => staff?.find((s) => s.id === option).username}
    />
  );
};

export default function TeamLeaderAutocompleteMemo(props) {
  return useMemo(() => <TeamLeaderAutocomplete {...props} />, [props]);
}
