/*
Цей компонент відображає всі події клієнта з використанням EventBox
 */
import EventBox from "@/components/comon/EventBox";
import { useParams } from "react-router-dom";

const SingleClientEvents = () => {
  const { clientId } = useParams();
  return (
    <>
      <EventBox clientId={clientId} />
    </>
  );
};

export default SingleClientEvents;
