import * as React from "react";
import Box from "@mui/material/Box";
import DataGridHeader from "@/components/MyDataGrid/DataGridHeader";

import useStaffColumns from "./useStaffColumns";
import { useStaffContext } from "@/contexts/StaffContext";
import SkeletonTable from "@/components/skeletons/SkeletonTable";
import CustomDataGrid from "@/components/MyDataGrid/CustomDataGrid";
import { useUpdateUserMutation } from "@/redux/api/usersApiSlice";
import useRowProcessUpdate from "@/components/MyDataGrid/CustomDataGrid/useRowProcessUpdate";
import AddStaff from "./AddStaff";

const StaffDataGrid = () => {
  const [updateUser] = useUpdateUserMutation();
  const { staff, isLoadingStaff, staffCount, groups, passwordHandler } =
    useStaffContext();
  const columns = useStaffColumns(
    staff || [],
    groups || [],
    passwordHandler,
    () => {},
  );

  const processRowUpdate = useRowProcessUpdate(updateUser);
  if (isLoadingStaff) {
    return <SkeletonTable numberOfRows={10} />;
  }

  return (
    <>
      <DataGridHeader title={"Staff"}>
        <AddStaff />
      </DataGridHeader>
      <Box sx={{ width: "100%" }}>
        <CustomDataGrid
          rowCount={staffCount || 0}
          rows={staff || []}
          autoHeight={true}
          noRowsOverlayText="No staff yet"
          columns={columns}
          processRowHandler={processRowUpdate}
          checkboxSelection={false}
        />
      </Box>
    </>
  );
};

export default StaffDataGrid;
