import { useGetPlanningTypesQuery } from "@/redux/api/planningApiSlice";
import { MenuItem, TextField } from "@mui/material";

const EventBoxTypeSelect = ({ errors, form, onChangeHandler }) => {
  const { data: typeList, isLoading } = useGetPlanningTypesQuery();
  if (isLoading) {
    return null;
  }
  return (
    <TextField
      sx={{ mb: 2 }}
      name="type"
      label="Event type"
      variant="outlined"
      margin="dense"
      size="small"
      select
      error={!!errors?.type}
      helperText={errors?.type || ""}
      onChange={onChangeHandler}
      value={form?.type || ""}
      fullWidth
    >
      {typeList?.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EventBoxTypeSelect;
