import { Stack } from "@mui/material";
import {
  selectPaginationModel,
  setPaginationModel,
} from "@/redux/reducers/baseSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback, useRef } from "react";
import { useSetSnackBar } from "@/hooks";

const CustomDataGrid = ({
  toolbar,
  onRowSelectionHandler,
  processRowHandler,
  noRowsOverlayText,
  ...rest
}) => {
  const paginationModel = useSelector(selectPaginationModel);
  const editCellRef = useRef(null);
  const snackBar = useSetSnackBar();
  const dispatch = useDispatch();

  const handleProcessRowUpdateError = useCallback(
    (error) => {
      const { data } = error;
      for (const key in data) {
        snackBar(data[key], "error", true);
      }
    },
    [snackBar],
  );
  return (
    <DataGrid
      autoHeight={true}
      sx={{ "& .MuiDataGrid-cell": { position: "relative" } }}
      slots={{
        noRowsOverlay: () => (
          <Stack
            sx={{ height: "100%", width: "100%" }}
            alignItems="center"
            justifyContent="center"
          >
            {noRowsOverlayText}
          </Stack>
        ),
        toolbar: toolbar,
      }}
      initialState={{
        pagination: {
          paginationModel,
        },
      }}
      pageSizeOptions={[20, 50, 100]}
      onRowSelectionModelChange={onRowSelectionHandler}
      onCellEditStop={(params) => {
        editCellRef.current = params.field;
      }}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      disableRowSelectionOnClick
      processRowUpdate={(newRow, oldRow) =>
        processRowHandler(newRow, oldRow, editCellRef)
      }
      disableColumnMenu
      onPaginationModelChange={(paginationModel) => {
        const { pageSize, page } = paginationModel;
        dispatch(setPaginationModel({ pageSize, page: page }));
      }}
      paginationMode="server"
      density="compact"
      {...rest}
    />
  );
};

export default memo(CustomDataGrid);
