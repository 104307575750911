import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { logOut, setCredentials } from "@/redux/reducers/authSlice";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState, endpoint }) => {
    // Додаємо токен до запиту якщо він є в сторі та якщо це не запит на оновлення токену або логін або вихід

    const notProtected = ["refresh", "login", "logout"];
    if (notProtected.includes(endpoint)) {
      return headers;
    }
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Базовий запит для виклику з перевіркою токену та перевикликом
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 423) {
    api.dispatch(logOut(result?.error?.data?.detail));
  }
  if (result?.error?.status === 401) {
    // якщо токен протух, оновлюємо його

    const refreshResult = await baseQuery(
      {
        url: "/users/refresh/",
        method: "POST",
      },
      { ...api, endpoint: "refresh" },
      extraOptions,
    );
    if (refreshResult?.data) {
      // якщо оновлення токену пройшло успішно, оновлюємо токен в сторі

      const newData = refreshResult.data;

      api.dispatch(setCredentials(newData));
      // робимо запит з новим токеном

      result = await baseQuery(args, api, extraOptions);
    } else {
      // якщо оновлення токену не пройшло, виходимо з акаунта
      api.dispatch(logOut());
    }
  }
  // повертаємо результат
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
