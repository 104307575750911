import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useCanExportClient } from "@/utils/permission";
import { useExportClientsToJSONMutation } from "@/redux/api/clientApiSlice";
import { exportToCsv } from "@/utils";
import { useSetSnackBar } from "@/hooks";

const ExportClients = () => {
  const canExport = useCanExportClient();
  const snackBar = useSetSnackBar();
  const [exportClients] = useExportClientsToJSONMutation();
  if (!canExport) return null;
  const onClick = async () => {
    try {
      const response = await exportClients().unwrap();
      await exportToCsv("clients", response);
      snackBar("Clients exported successfully!", "success", true);
    } catch (error) {
      snackBar(error.data.detail, "error", true);
    }
  };
  return (
    <>
      <Button onClick={onClick} endIcon={<FileDownloadOutlinedIcon />}>
        Export
      </Button>
    </>
  );
};

export default ExportClients;
