import clientFields from "./clientFields";
import { TextField } from "@mui/material";
import { canUpdateClientField } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

const SingleClientInfoInputGroup = ({
  onChangeHandler,
  client,
  errors,
  form,
}) => {
  const permissions = useSelector(selectPermissions);
  return (
    <>
      {clientFields.map(({ name, ...rest }) => (
        <TextField
          onChange={onChangeHandler}
          key={name}
          name={name}
          {...rest}
          variant="outlined"
          margin="dense"
          size="small"
          value={form[name] || client?.[name] || ""}
          InputProps={{
            readOnly: !canUpdateClientField(name, permissions),
          }}
          error={!!errors[name]}
          helperText={errors[name] && errors[name][0]}
          fullWidth
        />
      ))}
    </>
  );
};

export default SingleClientInfoInputGroup;
