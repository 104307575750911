import { memo, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";

export const formattedChecked = (checked) => {
  return Object.keys(checked)
    .filter((key) => checked[key])
    .map((k) => parseInt(k));
};
const CheckboxGroup = ({ options, legend, helperText, error, onChange }) => {
  const [checked, setChecked] = useState({});

  const handleChange = (event, value) => {
    setChecked((prev) => {
      const newChecked = { ...prev, [value]: event.target.checked };
      onChange(event, newChecked);
      return newChecked;
    });
  };

  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      <FormLabel component="legend" error={error}>
        {legend}
      </FormLabel>
      <FormGroup
        sx={{
          maxHeight: 200,
          overflowY: "auto",
          width: "100%",
          flexDirection: "column!important",
          flexWrap: "nowrap",
        }}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            error={error}
            sx={{ display: "flex" }}
            key={value}
            control={
              <Checkbox
                checked={checked[value] || false}
                error={error}
                onChange={(e) => handleChange(e, value)}
                name={label}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default memo(CheckboxGroup);
