/*
Це файл для створення апі, який буде відповідати за взаємодію з сервером.
 */

import { apiSlice } from "@/redux/api/apiSlice";

export const planningApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Отримання списку планувань з можливістю фільтрації по year, month, created_by, client
    getPlannings: builder.query({
      query: (params) => {
        params = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value),
        );
        return {
          url: "/plannings/",
          params,
        };
      },
    }),
    // Отримання планування по id
    getPlanning: builder.query({
      query: (id) => `/plannings/${id}/`,
    }),
    // Додавання нового планування
    createPlanning: builder.mutation({
      query: (data) => {
        return {
          url: "/plannings/",
          method: "POST",
          body: data,
        };
      },
    }),
    // Оновлення планування по id
    updatePlanning: builder.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/plannings/${id}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    // Видалення планування по id
    deletePlanning: builder.mutation({
      query: (id) => ({
        url: `/plannings/${id}/`,
        method: "DELETE",
      }),
    }),
    // Отримання списку статусів планування
    getPlanningStatuses: builder.query({
      query: () => "/plannings/statuses/",
    }),
    // Отримання списку типів планування
    getPlanningTypes: builder.query({
      query: () => "/plannings/types/",
    }),
  }),
});

export const {
  useGetPlanningsQuery,
  useGetPlanningQuery,
  useCreatePlanningMutation,
  useUpdatePlanningMutation,
  useDeletePlanningMutation,
  useGetPlanningStatusesQuery,
  useGetPlanningTypesQuery,
} = planningApiSlice;
