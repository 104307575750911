import { Autocomplete, Chip, TextField } from "@mui/material";
import { memo } from "react";

const AssignAutocomplete = ({ options, value, setValue, ...rest }) => {
  return (
    <Autocomplete
      {...rest}
      multiple
      fullWidth={true}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return <Chip label={option.username} {...getTagProps({ index })} />;
        })
      }
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.username}
      options={options}
      renderInput={(params) => (
        <TextField fullWidth={true} {...params} label="Select manager" />
      )}
    />
  );
};

export default memo(AssignAutocomplete);
