import { GridActionsCellItem } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useClientsDialogContext } from "@/contexts/ClientsDialogContext";

const ClientActions = (params) => {
  const { toggleDialog } = useClientsDialogContext();

  return [
    <GridActionsCellItem
      icon={<EditOutlinedIcon />}
      label="Edit"
      component={Link}
      to={`/clients/${params.row.id}`}
      target="_blank"
    />,
    <GridActionsCellItem
      label={"Comments"}
      icon={<ChatBubbleOutlineOutlinedIcon />}
      onClick={() => {
        toggleDialog(params.row.id);
      }}
    />,
  ];
};

export default ClientActions;
