export default function useDaysInMonth(cm = null) {
  const currentMonth = cm || new Date();

  return Array.from(
    {
      length: new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + 1,
        0,
      ).getDate(),
    },
    (_, index) => {
      return new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        index + 1,
      ).toISOString();
    },
  );
}
