import Modal from "@/components/ui/Modal";

import { memo, useState } from "react";
import AssignAutocomplete from "@/components/comon/AssignAutocomplete";
import { Button, InputAdornment, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useImportClientsFormCSVMutation } from "@/redux/api/clientApiSlice";
import leads from "./leads.csv";
import { useSetSnackBar } from "@/hooks";
import { useClientContext } from "@/contexts/ClientContext";
const ImportClientsForm = ({ open, setOpen }) => {
  const { assignedTo, clientsApiRef } = useClientContext();

  const snackBar = useSetSnackBar();
  const [value, setValue] = useState([]);
  const [file, setFile] = useState(null);
  const [importClients] = useImportClientsFormCSVMutation();
  const setFileHandler = (file) => {
    setFile(file);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("users_ids", Object.values(value).map((i) => i.id) || []);
    try {
      const response = await importClients(formData).unwrap();
      snackBar(response.detail, "success", true);
      setOpen(false);
      clientsApiRef.current.refetch();
    } catch (error) {
      snackBar(error.data.detail, "error", true);
    }
  };
  return (
    <Modal
      open={open}
      closeHandler={() => setOpen(false)}
      title={"Import Clients"}
      sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
    >
      <form className={"py-2"} onSubmit={handleSubmit}>
        <Typography gutterBottom={true} variant={"subtitle1"} component={"p"}>
          Select the manager to whom you want to assign the imported clients
        </Typography>
        <AssignAutocomplete
          value={value}
          setValue={setValue}
          options={assignedTo || []}
          sx={{ mb: 2 }}
        />
        <Typography gutterBottom variant={"caption"} component={"p"}>
          Select the csv file to import clients.
        </Typography>
        <Typography gutterBottom variant={"caption"} component={"p"}>
          The file should contain the fields: first_name, last_name, email,
          phone, source(optional), assigned_to(optional),
          country(optional),comment(optional)
        </Typography>

        <MuiFileInput
          sx={{ my: 2 }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachFileIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{
            accept: ".csv",
          }}
          label={"Select csv file"}
          placeholder="Select file"
          value={file}
          onChange={setFileHandler}
        />
        <div className={"flex items-center space-x-2"}>
          <Button
            variant="contained"
            type="submit"
            disableElevation
            size="large"
          >
            Import Clients
          </Button>
          <Button
            component={"a"}
            variant="text"
            size="large"
            download
            href={leads}
          >
            Download Sample
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default memo(ImportClientsForm);
