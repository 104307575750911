import { Button } from "@mui/material";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useSetSnackBar } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { canPromoteClient } from "@/utils/permission";
import { selectPermissions } from "@/redux/reducers/authSlice";
import { useSelector } from "react-redux";
import { usePromoteClientMutation } from "@/redux/api/clientApiSlice";

const PromoteClient = ({ type, clientId }) => {
  const [promoteClient] = usePromoteClientMutation();
  const snackbar = useSetSnackBar();
  const navigate = useNavigate();
  const perms = useSelector(selectPermissions);
  const canPromote = canPromoteClient(perms);

  if (type === "client" || !canPromote) return null;
  const promoteHandler = async () => {
    try {
      const response = await promoteClient([clientId]).unwrap();
      snackbar(response.detail, "success");
      navigate(`/`);
    } catch (error) {
      snackbar(error.data.detail, "error");
    }
  };
  return (
    <Button
      variant="contained"
      color="success"
      onClick={promoteHandler}
      endIcon={<UpgradeIcon />}
    >
      Promote
    </Button>
  );
};

export default PromoteClient;
