import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAuthenticated,
} from "@/redux/reducers/authSlice";
import { Navigate } from "react-router-dom";
import { canViewByModel } from "@/utils/permission";

export default function PrivateRoute({
  element: Element,
  app,
  model,
  ...rest
}) {
  const user = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const withPermissions = !app && !model;

  if (!withPermissions && !canViewByModel(app, model, user?.permissions)) {
    return <Navigate to="/403" />;
  }
  return <Element {...rest} />;
}
