import { useCanCreateByModel } from "@/utils/permission";
import { Alert, Fab, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { useEventBoxContext } from "@/contexts/EventBoxContext";
import EventBoxDateTimePicker from "@/components/comon/EventBox/EventBoxAdd/EventBoxDateTimePicker";
import EventBoxTypeSelect from "@/components/comon/EventBox/EventBoxAdd/EventBoxTypeSelect";
import { onEnterPress } from "@/utils";

const EventBoxAdd = () => {
  const initialForm = {
    dueDatetime: null,
    type: null,
  };
  const { addEvent, errors, setErrors, clientId } = useEventBoxContext();
  const [form, setForm] = useState(initialForm);

  const handlerAddEvent = (e) => {
    e.preventDefault();
    if (errors?.dueDatetime) {
      return;
    }
    if (Object.values(form).some((value) => !value)) {
      for (const key in form) {
        if (!form[key]) {
          setErrors((prev) => ({ ...prev, [key]: "This field is required" }));
        }
      }
      return;
    }
    const { dueDatetime, ...restForm } = form;
    addEvent({
      ...restForm,
      client: clientId,
      dueDatetime: dueDatetime.toISOString(),
    });
    setForm(initialForm);
  };

  const onChangeHandler = useCallback(
    (e) => {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      setErrors((prev) => ({ ...prev, [e.target.name]: null }));
    },
    [setForm, setErrors],
  );

  return (
    <form onSubmit={handlerAddEvent}>
      <EventBoxDateTimePicker {...{ form, setForm }} />
      <EventBoxTypeSelect {...{ errors, form, onChangeHandler }} />
      <div className={"relative"}>
        <TextField
          onChange={onChangeHandler}
          label="Add comment"
          name="description"
          multiline
          rows={2}
          fullWidth
          helperText={errors?.description || "Can shift+enter to add comment"}
          onKeyDown={(e) => onEnterPress(e, handlerAddEvent)}
          error={!!errors?.description}
          value={form.description || ""}
        />
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          sx={{ position: "absolute", right: 10, bottom: 10 }}
          type="submit"
        >
          <Add />
        </Fab>
      </div>
    </form>
  );
};

export default function Index() {
  const canAddEvent = useCanCreateByModel("planning", "planning");
  if (!canAddEvent) {
    return (
      <Alert severity="error" color="warning">
        You don't have permission to add event
      </Alert>
    );
  }
  return <EventBoxAdd />;
}
