import { ButtonGroup } from "@mui/material";
import DataGridHeader from "@/components/MyDataGrid/DataGridHeader";
import CreateClient from "./CreateClient";
import AssignedTo from "./AssignedTo";
import DeleteClients from "./DeleteClients";
import ImportClients from "./ImportClients";
import ExportClients from "./ExportClients";
import MassPromote from "./MassPromote";
import MassDemote from "./MassDemote";
import OnlyMyFilter from "./OnlyMyFilter";
const ClientsHeader = ({ title, clientsType }) => {
  return (
    <DataGridHeader
      title={
        <>
          {title} <OnlyMyFilter />
        </>
      }
    >
      {clientsType === "lead" && (
        <>
          <ButtonGroup variant="text" aria-label="Basic button group">
            <MassPromote />
            <AssignedTo />
            <DeleteClients />
            <ImportClients />
            <ExportClients />
          </ButtonGroup>
          <CreateClient />
        </>
      )}
      {clientsType === "client" && (
        <ButtonGroup variant="text" aria-label="Basic button group">
          <MassDemote />
          <AssignedTo />
          <DeleteClients />
        </ButtonGroup>
      )}
    </DataGridHeader>
  );
};

export default ClientsHeader;
