import { Box, Typography } from "@mui/material";

const DataGridHeader = ({ title, children }) => {
  return (
    <Box className={"py-3 px-5 flex items-center justify-between"}>
      <Typography variant="h6" component="h1" className="flex items-center">
        {title}
      </Typography>
      <Box className={"space-x-2 flex items-center"}>{children}</Box>
    </Box>
  );
};

export default DataGridHeader;
