import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { logOut } from "@/redux/reducers/authSlice";
import { useLogoutMutation } from "@/redux/api/authApiSlice";
import { useNavigate } from "react-router-dom";
const LogoutButton = () => {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const handleLogout = async () => {
    await logout();
    dispatch(logOut());
    navigate("/login");
  };
  return (
    <Tooltip title="Logout">
      <IconButton color="inherit" onClick={handleLogout}>
        <ExitToAppOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;
