import CheckboxGroup, { formattedChecked } from "@/components/ui/CheckboxGroup";

const AddStaffGroupCheckboxList = ({ groups, onChange, ...props }) => {
  const options = groups.map((group) => {
    return { value: group.id, label: group.name };
  });
  const onChangeHandler = (e, checked) => {
    onChange((prev) => ({ ...prev, groups: formattedChecked(checked) }));
  };
  return (
    <CheckboxGroup
      options={options}
      legend={"Choose group"}
      onChange={onChangeHandler}
    />
  );
};

export default AddStaffGroupCheckboxList;
