import { useGetLogsQuery } from "@/redux/api/logsApiSlice";
import { useSelector } from "react-redux";
import { selectPaginationModel } from "@/redux/reducers/baseSlice";
import { useEffect } from "react";

export default function useGetLogs(params = {}) {
  const { page, pageSize } = useSelector(selectPaginationModel);
  const {
    data: { count: logsCount, results: logs = [] } = {},
    isLoading,
    refetch,
  } = useGetLogsQuery({
    pageSize,
    page: page + 1 === 1 ? null : page + 1,
    ...params,
  });
  useEffect(() => {
    refetch();
  }, [refetch, page, pageSize]);
  return [logs, logsCount, isLoading];
}
