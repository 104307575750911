import { useDispatch } from "react-redux";
import { setSelectedClientsIds } from "@/redux/reducers/clientsSlice";
import Modal from "@/components/ui/Modal";
import { Button } from "@mui/material";
import { memo, useState } from "react";
import { useMassAssignClientsMutation } from "@/redux/api/clientApiSlice";
import AssignAutocomplete from "@/components/comon/AssignAutocomplete";
import { useSetSnackBar } from "@/hooks";
import { useClientContext } from "@/contexts/ClientContext";
const AssignedToForm = ({ open, setOpen, selectedClient }) => {
  const { assignedTo, clientsApiRef } = useClientContext();
  const snackBar = useSetSnackBar();
  const [massAssign] = useMassAssignClientsMutation();
  const [value, setValue] = useState([assignedTo[0]] || []);
  const dispatch = useDispatch();
  const handleAssign = async (e) => {
    e.preventDefault();
    const data = {
      clients: selectedClient,
      user_ids: value.map((item) => item.id),
    };
    try {
      const response = await massAssign(data).unwrap();
      snackBar(response.detail, "success", true);
      dispatch(setSelectedClientsIds([]));
      clientsApiRef.current.refetch();

      setOpen(false);
    } catch (error) {
      snackBar(error.data.detail, "error", true);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
      open={open}
      closeHandler={() => setOpen(false)}
      title={`${selectedClient.length} clients are selected for transfer`}
    >
      <form className={"py-2"} onSubmit={handleAssign}>
        <legend className={"mb-5"}>
          Select the manager to whom you want to assign the selected clients
        </legend>
        <AssignAutocomplete
          options={assignedTo}
          value={value}
          setValue={setValue}
        />
        <div className={"mt-5 flex items-center  space-x-3"}>
          <Button
            variant="contained"
            type="submit"
            disableElevation
            size="large"
          >
            Assign to {value?.username}
          </Button>
          <Button
            variant="text"
            color="error"
            size="large"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default memo(AssignedToForm);
