import { useParams } from "react-router-dom";
import { Paper, Grid, Divider } from "@mui/material";
import SingleClientComments from "@/pages/SingleClient/SingleClientComments";
import SingleClientInfo from "@/pages/SingleClient/SingleClientInfo";
import SingleClientEvents from "@/pages/SingleClient/SingleClientEvents";
const SingleClient = () => {
  const { clientId } = useParams();
  return (
    <>
      <Paper sx={{}}>
        <Grid container>
          <Grid sx={{ p: 2 }} item xs={12} md={3}>
            <SingleClientInfo clientId={clientId} />
          </Grid>
          <Divider sx={{ mr: "-1px" }} orientation="vertical" flexItem />
          <Grid sx={{ p: 2 }} item xs={12} md={4.5}>
            <SingleClientComments />
          </Grid>
          <Divider sx={{ mr: "-1px" }} orientation="vertical" flexItem />
          <Grid sx={{ p: 2 }} item xs={12} md={4.5}>
            <SingleClientEvents />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SingleClient;
