import { createContext, useCallback, useContext, useState } from "react";

const ClientsDialogContext = createContext({
  open: false,
  clientId: null,
  toggleDialog: () => {},
});

export const useClientsDialogContext = () => {
  return useContext(ClientsDialogContext);
};

export const ClientsDialogContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [clientId, setClientId] = useState(null);
  const toggleDialog = useCallback(
    (clientId) => {
      console.log(clientId, "clientId");
      setOpen((prev) => !prev);
      setClientId(clientId);
    },
    [setOpen, setClientId],
  );
  return (
    <ClientsDialogContext.Provider value={{ open, toggleDialog, clientId }}>
      {children}
    </ClientsDialogContext.Provider>
  );
};
