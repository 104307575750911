import { Box, Chip, MenuItem, Select } from "@mui/material";
import React from "react";

const CustomMultiSelect = (params) => {
  const options = params.colDef.valueOptions;
  const handleValueChange = async (event) => {
    const eValue = event.target?.value;
    const value = typeof eValue === "string" ? params.value.split(",") : eValue;
    await params.api.setEditCellValue({
      id: params.id,
      field: params.field,
      value: value,
    });
  };

  return (
    options?.length !== 0 && (
      <Select
        value={params.value || []}
        onChange={handleValueChange}
        multiple
        sx={{ width: "100%" }}
      >
        {options?.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    )
  );
};

export const CustomMultiSelectRenderCell = React.memo((params) => {
  const options = params?.colDef?.valueOptions;
  const value = params?.value;
  return (
    <Box className={"flex space-x-1 w-full overflow-x-auto"}>
      {options
        ?.filter((option) => value.includes(option.value))
        .map((option) => (
          <Chip
            size="small"
            key={option.value}
            variant="outlined"
            label={option.label}
          />
        ))}
    </Box>
  );
});

export const CustomMultiSelectValueFormatter = React.memo(
  ({ params, options }) => {
    console.log(params, options);
    return (
      options
        ?.filter((option) => params.value.includes(option.value))
        .map((option) => option.label)
        .join(",") || "–"
    );
  },
);
export default React.memo(CustomMultiSelect);
