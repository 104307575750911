import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedClientsIds,
  setSelectedClientsIds,
} from "@/redux/reducers/clientsSlice";
import { selectPermissions } from "@/redux/reducers/authSlice";
import { canDemoteClient } from "@/utils/permission";
import { Button } from "@mui/material";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { useDemoteClientMutation } from "@/redux/api/clientApiSlice";
import { useSetSnackBar } from "@/hooks";
import { useNavigate } from "react-router-dom";
import MassDemoteConfirm from "./MassDemoteConfirm";
const MassDemote = () => {
  const selectedClient = useSelector(selectSelectedClientsIds);
  const perms = useSelector(selectPermissions);
  const canDemote = canDemoteClient(perms);
  const [open, setOpen] = React.useState(false);
  const [demoteClient] = useDemoteClientMutation();
  const dispatch = useDispatch();

  const snackbar = useSetSnackBar();

  if (!canDemote || selectedClient.length === 0) {
    return null;
  }
  const demoteHandler = async () => {
    try {
      const response = await demoteClient(selectedClient).unwrap();
      snackbar(response.detail, "success");
      dispatch(setSelectedClientsIds([]));
      window.location.reload();
    } catch (error) {
      snackbar(error.data.detail, "error");
    }
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ px: 2 }}
        color="warning"
        endIcon={<VerticalAlignBottomIcon />}
      >
        Demote
      </Button>
      <MassDemoteConfirm
        open={open}
        setOpen={setOpen}
        handler={demoteHandler}
        count={selectedClient.length}
      />
    </>
  );
};

export default React.memo(MassDemote);
