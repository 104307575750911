import React, { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ConfirmAlert from "@/components/ui/ConfirmAlert";
import { useDeleteUserMutation } from "@/redux/api/usersApiSlice";
import { useStaffContext } from "@/contexts/StaffContext";
import { useSetSnackBar } from "@/hooks";
const DeleteUser = ({ userId, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [deleteUser] = useDeleteUserMutation();
  const { apiRef } = useStaffContext();
  const snackbar = useSetSnackBar();
  const handleDelete = async () => {
    try {
      await deleteUser(userId).unwrap();
      setOpen(false);
      apiRef.current.refetch();
      snackbar("User deleted", "success");
    } catch (error) {
      console.error(error);
      snackbar(error.data.detail, "error");
    }
  };
  return (
    <>
      <GridActionsCellItem {...rest} onClick={() => setOpen(true)} />
      <ConfirmAlert
        onAgreeCB={handleDelete}
        onCloseCB={() => setOpen(false)}
        open={open}
      >
        Are you sure you want to delete this user?
      </ConfirmAlert>
    </>
  );
};

export default React.memo(DeleteUser);
