import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedClientsIds,
  setSelectedClientsIds,
} from "@/redux/reducers/clientsSlice";
import { selectPermissions } from "@/redux/reducers/authSlice";
import { canPromoteClient } from "@/utils/permission";
import { Button } from "@mui/material";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import MassPromoteConfirm from "./MassPromoteConfirm";
import { usePromoteClientMutation } from "@/redux/api/clientApiSlice";
import { useSetSnackBar } from "@/hooks";
import { useNavigate } from "react-router-dom";
const MassPromote = () => {
  const selectedClient = useSelector(selectSelectedClientsIds);
  const perms = useSelector(selectPermissions);
  const canPromote = canPromoteClient(perms);
  const [open, setOpen] = React.useState(false);
  const [promoteClient] = usePromoteClientMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const snackbar = useSetSnackBar();

  if (!canPromote || selectedClient.length === 0) {
    return null;
  }
  const promoteHandler = async () => {
    try {
      const response = await promoteClient(selectedClient).unwrap();
      snackbar(response.detail, "success");
      dispatch(setSelectedClientsIds([]));
      navigate(`/`);
    } catch (error) {
      snackbar(error.data.detail, "error");
    }
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ px: 2 }}
        color="success"
        endIcon={<UpgradeIcon />}
      >
        Promote
      </Button>
      <MassPromoteConfirm
        open={open}
        setOpen={setOpen}
        handler={promoteHandler}
        count={selectedClient.length}
      />
    </>
  );
};

export default React.memo(MassPromote);
