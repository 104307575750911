import { Grid, Skeleton } from "@mui/material";
import React from "react";

const CalendarSkeleton = ({ daysInMonth }) => {
  return (
    <div>
      <Grid container spacing={1}>
        {daysInMonth?.map((_, i) => {
          return (
            <Grid key={i} item xs={4} sm={2}>
              <Skeleton variant="rectangular" height={200} width={"100%"} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CalendarSkeleton;
