import { memo } from "react";
import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteButton = ({ onClick, value }) => {
  if (!value) return null;
  return (
    <IconButton size="small" onClick={onClick}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};

const SearchInput = ({ value, onChange, onClearInput }) => {
  return (
    <TextField
      variant="standard"
      size="small"
      onChange={onChange}
      sx={{ width: 200 }}
      placeholder="Search"
      name="search"
      value={value || ""}
      InputProps={{
        endAdornment: (
          <DeleteButton value={value} onClick={() => onClearInput("search")} />
        ),
      }}
    />
  );
};

export default memo(SearchInput);
