import { Button } from "@mui/material";
import { useState } from "react";
import { useUpdateClientMutation } from "@/redux/api/clientApiSlice";
import SingleClientInfoInputGroup from "@/pages/SingleClient/SingleClientInfo/SingleClientInfoForm/SingleClientInfoInputGroup";
import SingleClientInfoSelectGroup from "@/pages/SingleClient/SingleClientInfo/SingleClientInfoForm/SingleClientInfoSelectGroup";
import { useSetSnackBar } from "@/hooks";

const SingleClientInfoForm = ({ client }) => {
  const [form, setForm] = useState({});
  const [updateClient] = useUpdateClientMutation();
  const [errors, setErrors] = useState({});
  const snackBar = useSetSnackBar();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (Object.values(form).length === 0) {
      snackBar("Nothing to update", "warning", true);
      return;
    }
    try {
      await updateClient({ ...form, id: client.id }).unwrap();
      snackBar("Client updated successfully!", "success", true);
    } catch (error) {
      if (error.data?.detail) {
        snackBar(error.data.detail, "error", true);
      } else {
        setErrors(error.data);
      }
    }
  };
  const onChangeHandler = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <SingleClientInfoSelectGroup
        {...{ form, client, onChangeHandler, errors }}
      />
      <SingleClientInfoInputGroup
        {...{ form, client, onChangeHandler, errors }}
      />
      <Button
        variant="contained"
        size="large"
        type={"submit"}
        fullWidth
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </form>
  );
};

export default SingleClientInfoForm;
