import StaffDataGrid from "@/components/MyDataGrid/StaffDataGrid";
import { Paper } from "@mui/material";
import StaffContextProvider from "@/contexts/StaffContext";
import { memo } from "react";

const Staff = () => {
  return (
    <Paper>
      <StaffContextProvider>
        <StaffDataGrid />
      </StaffContextProvider>
    </Paper>
  );
};

// export default Staff;

export default memo(Staff);
