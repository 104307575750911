import useGetUrlPathByIndex from "@/hooks/useGetUrlPathByIndex";

export default function useGetClientsType(user) {
  const CLIENT_TYPES = [
    {
      clientsType: "client",
      title: "Retentions",
    },
    {
      clientsType: "lead",
      title: "Sales",
    },
  ];
  const path = useGetUrlPathByIndex(1);
  return CLIENT_TYPES[path === "retentions" ? 0 : 1];
}
