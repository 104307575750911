import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import country from "country-list-js";

const sortHashTableAlphabetically = (hashTable) => {
  return Object.keys(hashTable)
    .sort()
    .reduce((acc, key) => {
      acc[key] = hashTable[key];
      return acc;
    }, {});
};
const countryMapToOption = () => {
  const allCountries = sortHashTableAlphabetically(country.all);
  return Object.values(allCountries).map((value) => {
    return {
      name: value?.name,
      code: value?.iso2,
    };
  });
};
const sortCountries = (countries, sortOrder) => {
  const sortedCountries = [...countries];
  sortedCountries.sort((a, b) => {
    const indexA = sortOrder.indexOf(a.code);
    const indexB = sortOrder.indexOf(b.code);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
  return sortedCountries;
};
const sortOrder = ["ES", "IT", "FR", "PL", "AU", "CA", "PT"];
const countries = sortCountries(countryMapToOption(), sortOrder);

const CountryFilter = ({ value, setSearchValue, onClearInput }) => {
  return (
    <Autocomplete
      disablePortal
      options={countries}
      onChange={(e, value) => {
        setSearchValue((prev) => ({ ...prev, country: value?.code }));
      }}
      sx={{ width: 200 }}
      value={countries.find((option) => option.code === value) || null}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.code}
      componentsProps={{
        clearIndicator: {
          onClick: () => {
            onClearInput("country");
          },
        },
      }}
      renderInput={({ sx, ...params }) => {
        return (
          <TextField
            variant="standard"
            {...params}
            size="small"
            sx={{ paddingTop: "0", ...sx }}
            name={"country"}
            placeholder={"Country"}
          />
        );
      }}
    />
  );
};

export default React.memo(CountryFilter);
