import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("token") || null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, ...user } = action.payload;
      state.user = user;
      state.token = accessToken;
      localStorage.setItem("token", accessToken);
      localStorage.setItem("user", JSON.stringify(user));
    },
    removeToken: (state) => {
      state.token = null;
      localStorage.removeItem("token");
    },
    logOut: (state, action) => {
      const error = action.payload;
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      const queryObject = {
        next: window.location.pathname,
      };
      if (error) {
        queryObject.error = error;
      }
      const queryString = new URLSearchParams(queryObject).toString();
      window.location.href = `/login?${queryString}`;
    },
  },
});

export const { setCredentials, logOut, removeToken } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state?.auth?.user;
export const selectCurrentToken = (state) => state?.auth?.token;

export const selectPermissions = (state) => state?.auth?.user?.permissions;
export const selectIsAuthenticated = (state) => !!state?.auth?.token;
