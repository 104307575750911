import Modal from "@/components/ui/Modal";
import { Button } from "@mui/material";
import React from "react";
const MassDemoteConfirm = ({ open, setOpen, count, handler }) => {
  return (
    <Modal
      open={open}
      closeHandler={() => setOpen(false)}
      title={`Demote ${count} clients?`}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <div className={"mt-5 flex items-center  space-x-3"}>
        <Button
          onClick={handler}
          variant="contained"
          color="error"
          type="submit"
          disableElevation
          size="large"
        >
          Delete
        </Button>
        <Button variant="text" size="large" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(MassDemoteConfirm);
