import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useCanCreateByModel } from "@/utils/permission";
import { useState } from "react";
import ClientCreateForm from "@/components/MyDataGrid/Clients/ClientsHeader/CreateClient/ClientCreateForm";

const CreateClient = () => {
  const canCreateClient = useCanCreateByModel("client", "client");
  const [open, setOpen] = useState(false);
  if (!canCreateClient) return null;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        disableElevation
        endIcon={<AddOutlinedIcon />}
      >
        Create
      </Button>
      <ClientCreateForm open={open} setOpen={setOpen} />
    </>
  );
};

export default CreateClient;
