import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { dateTimeToTime } from "@/utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
const getIcon = (isFinished) => {
  if (isFinished) {
    return <CheckCircleIcon fontSize="small" color="success" />;
  }
  return <AccessTimeOutlinedIcon fontSize="small" color="primary" />;
};

const CalendarEvent = ({
  typeName,
  isFinished,
  description,
  client,
  dueDatetime,
  clientName,
}) => {
  const navigateBlank = (url) => {
    window.open(url, "_blank");
  };
  const time = dateTimeToTime(dueDatetime);
  const textPreview =
    description?.length > 20 ? description.slice(0, 20) + "..." : description;
  return (
    <>
      <ListItem
        onClick={() => navigateBlank(`/clients/${client}`)}
        as={ListItemButton}
        disableGutters
        disabled={isFinished}
      >
        <ListItemText
          primary={
            <Tooltip title={description}>
              <span className={"flex items-center space-x-1"}>
                {getIcon(isFinished)}
                <span className={"text-xs"}>
                  {time} - {typeName} - {clientName}
                </span>
              </span>
            </Tooltip>
          }
          secondary={textPreview}
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default CalendarEvent;
