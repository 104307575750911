/**
 * @module redux/api/permissionApiSlice
 * @desc Цей модуль містить логіку redux для прав доступу
 */
import { apiSlice } from "@/redux/api/apiSlice";

export const permissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: (params) => ({
        url: "/permissions/",
        params,
      }),
    }),
    getAllPermissions: builder.query({
      query: () => "/permissions/all/",
    }),

    getPermission: builder.query({
      query: (id) => `/permissions/${id}/`,
    }),
    createPermission: builder.mutation({
      query: (permission) => ({
        url: "/permissions/",
        method: "POST",
        body: { ...permission },
      }),
    }),
    updatePermission: builder.mutation({
      query: (permission) => ({
        url: `/permissions/${permission.id}/`,
        method: "PUT",
        body: { ...permission },
      }),
    }),
    deletePermission: builder.mutation({
      query: (id) => ({
        url: `/permissions/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useGetPermissionQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation,
  useGetAllPermissionsQuery,
} = permissionApiSlice;
