import React, { useEffect, useState } from "react";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import CalendarDay from "./CalendarDay";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useDaysInMonth from "../../hooks/useDaysInMonth";
import { useGetPlanningsQuery } from "@/redux/api/planningApiSlice";
import { useGetUserFormLS } from "@/hooks";
import CalendarSkeleton from "./CalendarSkeleton";
const addEventsToDays = (days, events) => {
  return days.map((day) => {
    const dayEvents = events.filter((event) => {
      return dayjs(event.dueDatetime).date() === dayjs(day).date();
    });
    return { day, events: dayEvents };
  });
};
const currentYearAndMonth = (data) => {
  return {
    year: dayjs(data).year(),
    month: dayjs(data).month() + 1,
  };
};
function Calendar() {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const daysInMonth = useDaysInMonth(currentMonth);
  const [pageSize, setPageSize] = useState(50);
  const user = useGetUserFormLS();
  const {
    data: { results: eventData, count } = { results: [], count: 0 },
    isLoading,
    isError,
    error,
    refetch,
  } = useGetPlanningsQuery({
    created_by: user.id,
    pageSize: pageSize,
    ...currentYearAndMonth(currentMonth),
  });

  const calendarDays = addEventsToDays(daysInMonth, eventData);
  useEffect(() => {
    if (count > pageSize) {
      setPageSize(count);
    }
  }, [count, pageSize]);
  useEffect(() => {
    refetch();
  }, [pageSize, refetch]);
  if (isError) {
    return (
      <Alert severity="error" color="error">
        {error.data.detail}
      </Alert>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper sx={{ p: 2.5, background: "transparent" }} elevation={0}>
        <Box className={"flex items-center justify-between mb-4"}>
          <Typography variant="h6" component="h1">
            Calendar
          </Typography>
          <DatePicker
            value={dayjs(currentMonth)}
            label={"Select month and year"}
            openTo="month"
            views={["year", "month"]}
            onChange={(newValue) => setCurrentMonth(newValue.toDate())}
          />
        </Box>
        {isLoading ? (
          <CalendarSkeleton daysInMonth={daysInMonth} />
        ) : (
          <div>
            <Grid container spacing={1}>
              {calendarDays?.map(({ day, events }) => {
                return <CalendarDay key={day} day={day} events={events} />;
              })}
            </Grid>
          </div>
        )}
      </Paper>
    </LocalizationProvider>
  );
}

export default Calendar;
