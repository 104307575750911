import { mainNav } from "@/routes";
import MainNavItem from "./MainNavItem";
import { cn } from "@/utils";
import { Box } from "@mui/material";

import MainNavMob from "./MainNavMob";
import { canViewByModel } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

const MainNav = ({ className }) => {
  const permissions = useSelector(selectPermissions);
  return (
    <>
      <nav className={cn(className)} aria-label="Main Navbar">
        <Box component={"ul"} className={"flex"} maxWidth="lg">
          {mainNav.map((item, index) =>
            canViewByModel(item.app, item.model, permissions) ? (
              <MainNavItem {...item} key={index} />
            ) : null,
          )}
        </Box>
      </nav>
      <MainNavMob permissions={permissions} />
    </>
  );
};

export default MainNav;
