import "./App.css";
import "preline/preline";
import { useEffect, useMemo, useState } from "react";
import { RouterProvider } from "react-router-dom";
import router from "@/routes";
import { createTheme, ThemeProvider } from "@mui/material";

import ColorModeContext from "@/contexts/ColorModeContext";

const grey = {
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#e5e5e5",
  300: "#d4d4d4",
  400: "#a3a3a3",
  500: "#737373",
  600: "#525252",
  700: "#404040",
  800: "#262626",
  900: "#171717",
  950: "#0a0a0a",
};
function App() {
  const [mode, setMode] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    document.getElementsByTagName("html")[0].className = mode;
    document.body.style.backgroundColor =
      mode === "light" ? grey[50] : grey[950];
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const currentMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("theme", currentMode);
          return currentMode;
        });
      },
    }),
    [],
  );

  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: "#2563eb",
      },
      grey: grey,
      background: {
        default: mode === "light" ? grey[50] : grey[950],
        paper: mode === "light" ? "#fff" : grey[900],
      },
    },
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
