import ConfirmAlert from "@/components/ui/ConfirmAlert";
import { useGroupsContext } from "@/contexts/GroupsContext";
import { memo } from "react";
import { useDeleteGroupMutation } from "@/redux/api/groupApiSlice";
import { useSetSnackBar } from "@/hooks";

const GroupsDeleteModal = () => {
  const { selectedRole: role, groupApiRef } = useGroupsContext();
  const [deleteGroup] = useDeleteGroupMutation();
  const snackbar = useSetSnackBar();
  const open = role?.type === "delete";
  const closeHandler = () => groupApiRef.current.closeModals();
  const agreeHandler = async () => {
    try {
      await deleteGroup(role.id).unwrap();
      groupApiRef.current.closeModals();
      groupApiRef.current.refetch();
      closeHandler();
      snackbar("Role deleted successfully", "success");
    } catch (e) {
      console.log(e);
      snackbar("Error deleting role", "error");
    }
  };

  if (!open) return null;
  return (
    <ConfirmAlert
      open={open}
      onCloseCB={closeHandler}
      TransitionProps={{ onExited: groupApiRef.current.closeModals }}
      onAgreeCB={agreeHandler}
      title={"Delete group"}
    >
      {`Are you sure you want to delete ${role?.name} group?`}
    </ConfirmAlert>
  );
};

export default memo(GroupsDeleteModal);
