import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import LogoutButton from "@/components/comon/LogoutButton";
const Forbidden = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}
      className={"items-center justify-center"}
    >
      <Paper elevation={3} sx={{ p: 4, mt: 6 }}>
        <DoNotDisturbAltIcon sx={{ fontSize: 100 }} color={"error"} />
        <Typography as={"h1"} variant="h1">
          403
        </Typography>
        <Typography sx={{ mb: 3 }}>
          Forbidden - You don't have permission to access this page
        </Typography>
        <Button variant={"contained"} size="large" as={Link} to={"/"}>
          Go back to home
        </Button>
        <Typography component={"span"} sx={{ mx: 2 }}>
          or
        </Typography>
        <LogoutButton />
      </Paper>
    </Box>
  );
};

export default Forbidden;
