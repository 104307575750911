import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";

import { Button, Card, TextField, Typography } from "@mui/material";
import { useLoginMutation } from "@/redux/api/authApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "@/redux/reducers/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordField from "@/components/ui/PasswordField";

const Login = (props) => {
  const location = useLocation();
  const next = new URLSearchParams(location.search).get("next");
  const errorQ = new URLSearchParams(location.search).get("error");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.className = "dark:bg-slate-900 bg-gray-100  h-full py-16";
  }, []);

  const handleUsernameInput = (e) => setUsername(e.target.value);
  const handlePasswordInput = (e) => setPassword(e.target.value);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ username, password }).unwrap();
      dispatch(setCredentials(userData));
      setError(null);
      navigate(next || "/");
    } catch (error) {
      setError(error.data.detail);
    }
  };
  useEffect(() => {
    if (errorQ) {
      setError(errorQ);
    }
  }, [errorQ]);
  return (
    <section className={"flex items-center "}>
      <main className="w-full max-w-md mx-auto p-6">
        <Card variant="outlined" className={"p-4 sm:p-7"}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Sign in
          </Typography>
          {error && (
            <Typography variant="body2" color="error" textAlign="center">
              {error}
            </Typography>
          )}
          <form onSubmit={handleLogin} className={"mt-5"}>
            <div className="grid gap-y-4">
              <TextField
                required
                label="Username"
                name={"username"}
                type={"text"}
                autoComplete="username"
                onChange={handleUsernameInput}
              />

              <PasswordField
                inputProps={{
                  onChange: handlePasswordInput,
                  name: "current-password",
                  value: password || "",
                }}
                fullWidth
              />
              <Button
                variant="contained"
                size="large"
                className="!py-3"
                endIcon={<SendIcon />}
                type={"submit"}
              >
                {isLoading ? "Loading..." : "Sign in"}
              </Button>
            </div>
          </form>
        </Card>
      </main>
    </section>
  );
};

export default Login;
