import React from "react";
import { Grid, List, ListSubheader, Paper, Typography } from "@mui/material";
import CalendarEvent from "@/pages/Calendar/CalendarEvent";
import { formatDate, sortEventsByDue } from "@/utils";

function CalendarDay({ day, events }) {
  const hasEvents = events?.length > 0;

  return (
    <Grid item xs={4} sm={2}>
      <List
        sx={{ height: "200px", overflow: "auto" }}
        as={Paper}
        dense
        disablePadding
        elevation={0}
      >
        <ListSubheader sx={{ lineHeight: "normal", py: 1.5 }}>
          {formatDate(day)}
        </ListSubheader>

        {!hasEvents ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ p: 2 }}
            component={"li"}
          >
            No events
          </Typography>
        ) : (
          sortEventsByDue(events).map(({ id, ...event }) => (
            <CalendarEvent {...event} eventId={id} key={id} />
          ))
        )}
      </List>
    </Grid>
  );
}

export default CalendarDay;
