/**
 * @module redux/api/groupApiSlice
 * @desc Цей модуль містить логіку redux для груп користувачів
 */

import { apiSlice } from "@/redux/api/apiSlice";

export const groupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: (params) => {
        params = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value),
        );
        return {
          url: "/groups/",
          method: "GET",
          params,
        };
      },
    }),

    getGroup: builder.query({
      query: (id) => ({
        url: `/groups/${id}/`,
        method: "GET",
      }),
    }),
    createGroup: builder.mutation({
      query: (group) => ({
        url: "/groups/",
        method: "POST",
        body: { ...group },
      }),
    }),
    updateGroup: builder.mutation({
      query: (group) => ({
        url: `/groups/${group.id}/`,
        method: "PUT",
        body: { ...group },
      }),
    }),
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `/groups/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupApiSlice;
