import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import MainHeader from "./MainHeader";
import { Alert, Box, Container, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSnackbar } from "@/redux/reducers/baseSlice";
import { useSetSnackBar } from "@/hooks";

const MainLayout = () => {
  const {
    open: snackBarOpen,
    message: snackbarMessage,
    ...snackbarProps
  } = useSelector(selectSnackbar);
  const snackBar = useSetSnackBar();
  const handleCloseSnackbar = () => {
    snackBar("", "info", false);
  };
  useEffect(() => {
    document.body.classList.remove(
      "dark:bg-slate-900",
      "bg-gray-100",
      "h-full",
      "py-16",
    );
  }, []);
  return (
    <>
      <MainHeader />

      <Container as="section" maxWidth={false}>
        <Box as={"section"} className={"py-10"}>
          <Outlet />
        </Box>
      </Container>
      {snackBarOpen && (
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert
            {...snackbarProps}
            children={snackbarMessage}
            onClose={handleCloseSnackbar}
          />
        </Snackbar>
      )}
    </>
  );
};

export default MainLayout;
