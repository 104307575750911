import { createContext, useContext, useRef, useState } from "react";
import {
  useGetGroupsQuery,
  useUpdateGroupMutation,
} from "@/redux/api/groupApiSlice";
import { useSelector } from "react-redux";
import { selectPaginationModel } from "@/redux/reducers/baseSlice";
import { useGetAllPermissionsQuery } from "@/redux/api/permissionApiSlice";

const GroupsContext = createContext({
  selectedRole: {},
  selectedModal: null,
});

export const useGroupsContext = () => {
  return useContext(GroupsContext);
};

export const GroupsContextProvider = ({ children }) => {
  const { page, pageSize } = useSelector(selectPaginationModel);
  const [selectedRole, setSelectedRole] = useState(null);
  const { data: allPermissions } = useGetAllPermissionsQuery();
  const {
    data: { results: groups, count } = {},
    isLoading,
    refetch,
  } = useGetGroupsQuery({
    pageSize,
    page: page + 1,
  });

  const [updateGroup] = useUpdateGroupMutation();
  const groupApiRef = useRef(null);
  const closeModals = () => {
    setSelectedRole(null);
  };
  const openModal = (role, type) => {
    setSelectedRole({ ...role, type });
  };
  groupApiRef.current = {
    openModal,
    closeModals,
    refetch,
    updateGroup,
  };
  return (
    <GroupsContext.Provider
      value={{
        groups,
        allPermissions,
        groupsCount: count,
        isLoading,
        selectedRole,
        groupApiRef,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};
