import React, { memo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetAssignedToListQuery } from "@/redux/api/usersApiSlice";

const UserFilter = ({ userId, handleFiltersChange, removeSearchParams }) => {
  const { data: assignedTo = [], isLoading } = useGetAssignedToListQuery();
  if (isLoading) return null;
  const options = assignedTo.map((user) => ({
    value: user.id,
    label: user.username,
  }));
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.value === value.value}
      size="small"
      onChange={(event, newValue) => {
        if (newValue) {
          handleFiltersChange("userId", newValue.value);
        } else {
          removeSearchParams("userId");
        }
      }}
      value={options.find((o) => o.value === parseInt(userId)) || null}
      options={options}
      sx={{ width: 150 }}
      renderInput={(params) => <TextField {...params} label="User" />}
    />
  );
};

export default memo(UserFilter);
