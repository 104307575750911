import { Box } from "@mui/material";
import { ClientsDialogContextProvider } from "@/contexts/ClientsDialogContext";
import ClientsCommentsDialog from "@/components/MyDataGrid/Clients/ClientsCommentsDialog";
import CustomSearchToolbar from "@/components/MyDataGrid/CustomSearchToolbar";
import { useDispatch, useSelector } from "react-redux";

import useGetColumns from "@/hooks/useGetColumns";
import { useUpdateClientMutation } from "@/redux/api/clientApiSlice";
import {
  selectSelectedClientsIds,
  setSelectedClientsIds,
} from "@/redux/reducers/clientsSlice";
import CustomDataGrid from "@/components/MyDataGrid/CustomDataGrid";
import useRowProcessUpdate from "@/components/MyDataGrid/CustomDataGrid/useRowProcessUpdate";
import SkeletonTable from "@/components/skeletons/SkeletonTable";
import { useClientContext } from "@/contexts/ClientContext";
import { memo } from "react";
const Clients = (props) => {
  const [updateClient] = useUpdateClientMutation();
  const dispatch = useDispatch();
  const columns = useGetColumns();
  const selectedClientsId = useSelector(selectSelectedClientsIds);
  const processRowUpdate = useRowProcessUpdate(updateClient);
  const { clients, clientsLoading, clientsCount, clientsIsFetching } =
    useClientContext();
  if (clientsLoading || clientsIsFetching)
    return (
      <SkeletonTable numberOfRows={localStorage.getItem("pageSize") || 20} />
    );
  return (
    <ClientsDialogContextProvider>
      <Box sx={{ width: "100%" }}>
        <CustomDataGrid
          rows={clients || []}
          columns={columns}
          toolbar={CustomSearchToolbar}
          rowCount={clientsCount}
          checkboxSelection
          rowSelectionModel={selectedClientsId}
          onRowSelectionHandler={(newSelection) => {
            dispatch(setSelectedClientsIds(newSelection));
          }}
          noRowsOverlayText={"No clients found"}
          processRowHandler={processRowUpdate}
        />
      </Box>
      <ClientsCommentsDialog />
    </ClientsDialogContextProvider>
  );
};

export default memo(Clients);
