import { memo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useClientContext } from "@/contexts/ClientContext";
import { canUpdateClientField } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";

const OnlyMyFilter = () => {
  const { onlyMy, clientsApiRef } = useClientContext();
  const perms = useSelector(selectPermissions);
  const canAssign = canUpdateClientField("assigned_to", perms);
  if (!canAssign) {
    return null;
  }
  return (
    <FormControlLabel
      value={onlyMy}
      sx={{ ml: 0.5 }}
      control={
        <Checkbox
          size="small"
          onChange={(e) => {
            clientsApiRef.current.setOnlyMy(e.target.checked);
          }}
          checked={onlyMy}
          color="primary"
          inputProps={{ "aria-label": "only my" }}
        />
      }
      label="Only My"
      labelPlacement="end"
    />
  );
};

export default memo(OnlyMyFilter);
