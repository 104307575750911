import { useGroupsContext } from "@/contexts/GroupsContext";
import Modal from "@/components/ui/Modal";
import { memo, useState } from "react";
import { Button, TextField } from "@mui/material";
import CheckboxGroup, { formattedChecked } from "@/components/ui/CheckboxGroup";
import { useCreateGroupMutation } from "@/redux/api/groupApiSlice";
import { useSetSnackBar } from "@/hooks";
const AddButton = (props) => {
  return (
    <Button autoFocus variant="contained" size="large" fullWidth {...props}>
      Add Group
    </Button>
  );
};
const GroupsAddModal = () => {
  const [form, setForm] = useState({});
  const [createGroup, { isLoading }] = useCreateGroupMutation();
  const {
    selectedRole: role,
    groupApiRef,
    allPermissions,
  } = useGroupsContext();
  const snackbar = useSetSnackBar();
  const open = role?.type === "add";
  if (!open) return null;
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await createGroup(form).unwrap();
      groupApiRef.current.closeModals();
      groupApiRef.current.refetch();
      snackbar("Group added successfully", "success");
    } catch (e) {
      console.log(e);
      snackbar("Error adding group", "error");
    }
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      fullWidth
      closeHandler={groupApiRef.current.closeModals}
      TransitionProps={{ onExited: groupApiRef.current.closeModals }}
      title={`Add group`}
    >
      <form onSubmit={onSubmitHandler}>
        <TextField
          label={"Group Name"}
          sx={{ mb: 2 }}
          fullWidth
          onChange={(e) =>
            setForm((prev) => ({ ...prev, name: e.target.value }))
          }
          value={form.name || ""}
          required
          variant="outlined"
        />
        <CheckboxGroup
          legend={"Permissions"}
          onChange={(e, checked) =>
            setForm((prev) => ({
              ...prev,
              permissions: formattedChecked(checked),
            }))
          }
          options={allPermissions.map((perm) => ({
            value: perm.id,
            label: perm.name,
          }))}
        />
        <AddButton disabled={isLoading} type="submit" sx={{ mt: 2 }} />
      </form>
    </Modal>
  );
};

export default memo(GroupsAddModal);
