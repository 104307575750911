import { useCanImportClient } from "@/utils/permission";
import { Button } from "@mui/material";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { useState } from "react";
import ImportClientsForm from "@/components/MyDataGrid/Clients/ClientsHeader/ImportClients/ImportClientsForm";

const ImportClients = () => {
  const canImport = useCanImportClient();
  const [open, setOpen] = useState(false);
  if (!canImport) return null;
  return (
    <>
      <Button
        sx={{ px: 2 }}
        onClick={() => setOpen(true)}
        endIcon={<PublishOutlinedIcon />}
      >
        Import
      </Button>
      <ImportClientsForm setOpen={setOpen} open={open} />
    </>
  );
};

export default ImportClients;
