import CommentBox from "@/components/comon/CommentBox/CommentBox";
import { useParams } from "react-router-dom";

const SingleClientComments = () => {
  const { clientId } = useParams();

  return <CommentBox clientId={clientId} />;
};

export default SingleClientComments;
