import { Button, TextField } from "@mui/material";
import Modal from "@/components/ui/Modal";
import PasswordField from "@/components/ui/PasswordField";
import { useStaffContext } from "@/contexts/StaffContext";
import AddStaffGroupCheckboxList from "@/components/MyDataGrid/StaffDataGrid/AddStaff/AddStaffGroupCheckboxList";
import { Fragment, useState } from "react";
import { useCreateUsersMutation } from "@/redux/api/usersApiSlice";
import { useSetSnackBar } from "@/hooks";
import TeamLeaderAutocomplete from "@/components/MyDataGrid/StaffDataGrid/AddStaff/TeamLeaderAutocomplete";
import { parseError } from "@/utils";

const HelpText = () => {
  return (
    <>
      - Password can’t be too similar to your other personal information.
      <br /> - Password must contain at least 8 characters.
      <br /> - Password can’t be a commonly used password.
      <br /> - Password can’t be entirely numeric.
    </>
  );
};

const SaveButton = (props) => {
  return (
    <Button autoFocus variant="contained" size="large" fullWidth {...props}>
      Add user
    </Button>
  );
};
const AddStaffForm = ({ open, setOpen }) => {
  const { staff, groups, apiRef } = useStaffContext();
  const [form, setForm] = useState({});
  const [createUser] = useCreateUsersMutation();
  const [errors, setErrors] = useState({});
  const snackbar = useSetSnackBar();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
  };
  const onSave = async (e) => {
    e.preventDefault();
    try {
      const response = await createUser(form).unwrap();
      setOpen(false);
      setErrors({});
      setForm({});
      apiRef.current.addStaff(response);
      snackbar("User added", "success");
    } catch (error) {
      if (error?.data?.detail) {
        return snackbar(error.data.detail, "error");
      }
      for (const field in error.data) {
        setErrors((prev) => ({ ...prev, [field]: error.data[field] }));
      }
    }
  };
  return (
    <Modal
      title={"Add new user"}
      open={open}
      closeHandler={() => setOpen(false)}
    >
      <form onSubmit={onSave} className={"py-2"}>
        <TextField
          onChange={handleChange}
          name={"username"}
          value={form?.username || ""}
          label={"Username"}
          fullWidth
          error={!!errors?.username}
          helperText={parseError(errors?.username) || ""}
        />
        <PasswordField
          inputProps={{
            onChange: handleChange,
            name: "password",
            autoComplete: "new-password",
            value: form?.password || "",
          }}
          error={!!errors?.password}
          fullWidth
          sx={{ my: 2 }}
          helpText={
            errors?.password ? parseError(errors?.password) : <HelpText />
          }
        />

        {groups && groups.length > 0 && (
          <AddStaffGroupCheckboxList groups={groups} onChange={setForm} />
        )}
        <TeamLeaderAutocomplete {...{ form, setForm, staff }} sx={{ mt: 2 }} />
        <SaveButton type="submit" sx={{ mt: 2 }} />
      </form>
    </Modal>
  );
};

export default AddStaffForm;
