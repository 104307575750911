import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const Modal = ({
  open,
  closeHandler,
  children,
  title,
  fullWidth = true,
  sx,
  ...rest
}) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-container": { alignItems: "flex-start" }, ...sx }}
      onClose={closeHandler}
      open={open}
      {...rest}
      fullWidth={fullWidth}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
