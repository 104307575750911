import { IconButton } from "@mui/material";
import { useMemo } from "react";
import { canChangeByModel, canDeleteByModel } from "@/utils/permission";
import { useSelector } from "react-redux";
import { selectPermissions } from "@/redux/reducers/authSlice";
import CustomMultiSelect, {
  CustomMultiSelectRenderCell,
} from "@/components/MyDataGrid/CustomMultiSelect";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const formatColumns = (column, permissions) => {
  const canChange = canChangeByModel("auth", "group", permissions);
  const newColumn = {
    ...column,
    editable: canChange,
  };
  if (column.field === "actions") {
    return {
      ...newColumn,
      hide: !canDeleteByModel("auth", "group", permissions),
      editable: false,
    };
  }
  return newColumn;
};

const permissionsToSelect = (permissions) => {
  return permissions?.map((permission) => ({
    value: permission.id,
    label: permission.name,
  }));
};

export default function useGroupColumns(groupApiRef, allPermissions) {
  const permissions = useSelector(selectPermissions);

  return useMemo(() => {
    const columns = [
      { field: "id", headerName: "ID", width: 100 },
      {
        field: "name",
        headerName: "Name",
        width: 150,
        editable: false,
        sortable: false,
      },
      {
        field: "permissions",
        type: "singleSelect",
        headerName: "Permissions",
        flex: 1,
        editable: false,
        sortable: false,
        valueOptions: permissionsToSelect(allPermissions),
        renderCell: (params) => {
          return <CustomMultiSelectRenderCell {...params} />;
        },
        renderEditCell: (params) => <CustomMultiSelect {...params} />,
      },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params) => (
          <>
            <IconButton
              color="error"
              onClick={() =>
                groupApiRef.current.openModal(params.row, "delete")
              }
            >
              <DeleteOutlineIcon color="inherit" />
            </IconButton>
          </>
        ),
      },
    ];
    return columns.map((col) => formatColumns(col, permissions));
  }, [groupApiRef, permissions, allPermissions]);
}
