import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar } from "@/redux/reducers/baseSlice";

export default function useSetSnackBar() {
  const dispatch = useDispatch();

  return useCallback(
    (message, severity, open = true) => {
      dispatch(setSnackbar({ message, severity, open }));
    },
    [dispatch],
  );
}
// useSetSnackBar
